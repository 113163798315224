import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';
import { extractEmailDomain } from 'utils/appHelpers';
import update from 'lodash/update';
import cloneDeep from 'lodash/cloneDeep';

const initialState = null;

const handlers = {
  [AUTH.LOGIN]: (state, action) => ({
    ...action.payload,
    email_domain: extractEmailDomain(action.payload.email),
  }),
  [USER.UPDATE_ACCOUNT_TEAM_NAME]: (state, action) => ({
    ...state,
    team: { ...state.team, name: action.payload },
  }),
  [USER.UPDATE_ACCOUNT_DATA_PARTIALLY]: (state, action) => {
    const { path, value } = action.payload;
    return update(cloneDeep(state), path, () => value);
  },
  [USER.UPDATE_ACCOUNT_DATA]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
