import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';
import update from 'lodash/update';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import {
  checkSpeakersAssignment,
  constructEditedTranscriptRowContent,
  excludeDeletedMeetings,
} from 'utils/meetingNotesHelpers';

const initialState = {};

const handlers = {
  [USER.SET_MEETING]: (state, action) => {
    const { id, data } = action.payload;
    const clonedState = cloneDeep(state);
    const updatedState = update(clonedState, `meetingsById.${id}`, (existingMeeting) => ({
      ...existingMeeting,
      ...data,
    }));
    return updatedState;
  },
  [USER.UPDATE_MEETING]: (state, action) => {
    if (!state.meetingsById) return state;
    const { path, data } = action.payload;
    if (!path || !data) return state;
    const clone = cloneDeep(state.meetingsById);
    update(clone, path, (old) => ({ ...old, ...data }));
    const id = path.split('.')[0];
    const speakersAssignmentState = checkSpeakersAssignment(
      clone[id].transcript?.content,
      clone[id].speakers,
    );
    if (id) {
      update(clone, id, (old) => ({
        ...old,
        ...speakersAssignmentState,
        has_some_note:
          !!old.detailed_summary || !!old.action_items || !!old.next_steps || !!old.key_points,
        notes_global_fetching:
          old.detailed_summary?.status === 'pending' && old.action_items?.status === 'pending',
      }));
    }
    return { ...state, meetingsById: clone };
  },
  [USER.SET_RECURRING_MEETINGS]: (state, action) => {
    const { recurringEventId, data } = action.payload;
    if (!recurringEventId || !data) return state;
    const clone = cloneDeep(state.recurringMeetingsById || {});
    update(clone, recurringEventId, (cache = { rows: [], count: 0 }) => {
      const { filteredList, countDiff } = excludeDeletedMeetings(data.rows, state.deletedMeetings);
      return {
        rows: uniqBy([...cache.rows, ...filteredList], 'id'),
        count: data.count - countDiff,
      };
    });
    return { ...state, recurringMeetingsById: clone };
  },
  [USER.SET_MEETING_ACCESSES]: (state, action) => {
    if (!state.meetingsById) return state;
    const { meetingId, data } = action.payload;
    if (!meetingId || !data) return state;
    const clone = cloneDeep(state.meetingsById);
    update(clone, `${meetingId}.accesses`, () => data);
    return { ...state, meetingsById: clone };
  },
  [USER.DELETE_MEETING]: (state, action) => {
    if (!action.payload.id) return state;
    const clonedMeetingsById = cloneDeep(state.meetingsById);

    if (clonedMeetingsById?.[action.payload.id]) {
      delete clonedMeetingsById[action.payload.id];
    }
    return {
      ...state,
      meetingsById: clonedMeetingsById,
    };
  },
  [USER.UPDATE_MEETING_ROW]: (state, action) => {
    if (!state.meetingsById) return state;
    const { id, data } = action.payload;
    if (!id || !data) return state;
    const clone = cloneDeep(state.meetingsById);
    update(clone, `${id}.transcript.content`, (oldData) => {
      return oldData.map((item) => {
        if (item.id !== data.speechId) return item;
        const speechStart = Number(item.speech.wordStartTime?.[0]?.toFixed(1));
        const speechEnd = Number(item.speech.end.toFixed(1));

        update(clone, `${id}.transcript.editedRanges`, (oldData = []) => {
          return [...oldData, [speechStart, speechEnd]];
        });

        return {
          ...item,
          speech: {
            ...item.speech,
            text: data.newSpeechData,
            text_chunks: constructEditedTranscriptRowContent(
              data.newSpeechData,
              speechStart,
              speechEnd,
            ),
            original_text: item.speech.original_text || item.speech.text,
            is_edited: true,
          },
        };
      });
    });
    return { ...state, meetingsById: clone };
  },
  [USER.UPDATE_MEETING_TRANSCRIPT]: (state, action) => {
    if (!state.meetingsById) return state;
    const { path, data } = action.payload;
    if (!path || !data) return state;
    const transcriptId = data.transcript_id;
    delete data.transcript_id;
    const clone = cloneDeep(state.meetingsById);
    const transcript = get(clone, path);
    if (!transcript) return state;
    const { speaker_index, ...person } = data;
    const speakers = clone[transcriptId]?.speakers || {};
    speakers[speaker_index] = { person };
    const speakersAssignmentState = checkSpeakersAssignment(
      clone[transcriptId].transcript.content,
      clone[transcriptId].speakers,
    );
    update(clone, `${transcriptId}`, (resource) => ({
      ...resource,
      ...speakersAssignmentState,
      speakers,
    }));
    return { ...state, meetingsById: clone };
  },
  [USER.UPDATE_MEETING_TRANSCRIPT_PARTIAL]: (state, action) => {
    if (!state.meetingsById) return state;
    const { path, data } = action.payload;
    if (!path || !data) return state;
    delete data.transcript_id;
    const clone = cloneDeep(state.meetingsById);
    const transcriptContent = get(clone, path);
    if (!transcriptContent) return state;
    const { new_speaker_index, speech_item_id } = data;
    if (new_speaker_index === undefined || speech_item_id === undefined) return;
    transcriptContent.forEach((chunk) => {
      if (chunk.id === speech_item_id) {
        chunk.speakerIndex = new_speaker_index;
      }
    });
    update(clone, path, () => transcriptContent);
    return { ...state, meetingsById: clone };
  },
  [USER.UPDATE_MEETING_NOTE_IN_LIST]: (state, action) => {
    if (!state?.list?.rows) return state;
    const { id, data } = action.payload;
    if (!id || !data) return state;
    const clone = cloneDeep(state.list.rows).map((item) => {
      if (item.id === id && item.meeting_note) {
        item = { ...item, meeting_note: { ...item.meeting_note, ...data } };
      }
      return item;
    });
    return { ...state, list: { ...state.list, rows: clone } };
  },
  [USER.COLLECT_USER_CONTACTS]: (state, action) => {
    const allContacts = action.payload.map((el) => {
      if (el.provider === 'custom') {
        delete el.email;
      }
      return el;
    });

    const contactsWithEmail = filter(allContacts, 'email');
    const directoryContacts = filter(contactsWithEmail, { type: 'directory' });
    const uniqDirectoryContacts = uniqBy(directoryContacts, 'email');

    const personalContacts = filter(contactsWithEmail, { type: 'personal' });
    const uniqPersonalContacts = filter(
      uniqBy(personalContacts, 'email'),
      (el) => !some(uniqDirectoryContacts, { email: el.email }),
    );

    const uniqContactsWithEmail = concat(uniqDirectoryContacts, uniqPersonalContacts);
    const customOnes = filter(allContacts, (el) => el.id && !el.email);

    return {
      ...state,
      contacts: uniqContactsWithEmail.concat(uniqBy(customOnes, 'id')),
    };
  },
  [USER.SET_NEW_SPEAKERS]: (state, action) => {
    if (!state.meetingsById) return state;
    const { path, data } = action.payload;
    if (!path || !data) return state;
    const clone = cloneDeep(state.meetingsById);
    update(clone, path, () => ({ ...data }));
    return { ...state, meetingsById: clone };
  },
  [USER.UPDATE_MEETING_SPEAKER]: (state, action) => {
    if (!state.meetingsById) return state;
    const { id, data } = action.payload;
    if (!id || !data) return state;
    const clone = cloneDeep(state.meetingsById);
    const speakers = clone[id].speakers;
    speakers[data.speaker_index] = { person: { ...speakers[data.speaker_index].person, ...data } };
    return { ...state, meetingsById: clone };
  },
  [USER.CHECK_TRANSCRIPT_VISIBILITY_AFTER_SPEECH_EDIT]: (state, action) => {
    if (!state.meetingsById) return state;
    const { id } = action.payload;
    if (!id) return state;
    const clone = cloneDeep(state.meetingsById);
    // eslint-disable-next-line prettier/prettier
    if (!clone[id].transcript?.content || clone[id].transcript?.content.some((el) => el.speech.text)) {
      if (clone[id].transcript?.noVisibleContent) {
        update(clone, `${id}.transcript`, (old) => ({ ...old, noVisibleContent: false }));
        return { ...state, meetingsById: clone };
      }
      return state;
    }
    update(clone, `${id}.transcript`, (old) => ({ ...old, noVisibleContent: true })); // Setting data to empty object to make UI corresponding to view without transcript
    return { ...state, meetingsById: clone };
  },
  [USER.SET_SELECTED_ATTENDEES_FOR_ACCESS]: (state, action) => {
    const { id, attendee } = action.payload;
    const clonedState = cloneDeep(state);
    const updatedState = update(
      clonedState,
      `meetingsById.${id}.selectedAttendees`,
      (selectedAttendees = []) => {
        if (action.payload.attendees) return action.payload.attendees;

        if (selectedAttendees.find((a) => a.email === attendee.email)) {
          return selectedAttendees.filter((a) => a.email !== attendee.email);
        } else {
          return [...selectedAttendees, attendee];
        }
      },
    );
    return updatedState;
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
