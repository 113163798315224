import React from 'react';
import * as Sentry from '@sentry/react';

// Load Components
import Alert from 'shared/components/vendor/Alert';

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className='container d-flex align-items-center justify-content-center h-100-vh'>
          <Alert type='danger'>An error occurred. Please contact to support administrator.</Alert>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
