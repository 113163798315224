/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';

// Load Vendors
import { SnackbarContent, useSnackbar } from 'notistack';

// Load Components
import { Toast } from '@krispai/kr-ui-components';

const ToastWrapper = React.forwardRef(
  ({ id, message, variant, actionText, action, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const closeHandler = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref} className='d-flex justify-content-center align-items-center'>
        <Toast
          type={variant}
          message={message}
          onClose={closeHandler}
          showClose
          actionText={actionText}
          showAction={!!actionText}
          onActionClick={action}
        />
      </SnackbarContent>
    );
  },
);

export default ToastWrapper;
