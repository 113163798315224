import { useEffect } from 'react';

const useOutsideClick = (refs, handler, disabled = false) => {
  refs = [].concat(refs); // Ensure refs is an array
  useEffect(() => {
    const listener = (event) => {
      if (disabled) return;
      if (
        refs.some((ref) => {
          if (typeof ref === 'string') return document.getElementById(ref)?.contains(event.target);
          if (ref?.current) return ref.current?.contains(event.target);
        })
      )
        return;
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
    //eslint-disable-next-line
  }, [refs, handler]);
};

export default useOutsideClick;
