import { createReducer } from 'utils/reduxHelpers';
import { RESOURCE } from 'redux/actionTypes';

const initialState = {};

const handlers = {
  [RESOURCE.SET_CHAT]: (state, action) => ({ ...state, chat: action.payload }),
};

export default createReducer(initialState, handlers);
