import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';

const initialState = null;

const handlers = {
  [USER.SET_SEATS]: (state, action) => {
    const { seatsInfo, ...rest } = action.payload;
    return {
      ...rest,
      seatsInfo: {
        ...seatsInfo,
        assigned: seatsInfo?.assigned ?? 0,
        unassigned: seatsInfo?.unassigned ?? 0,
      },
    };
  },
  [USER.REMOVE_SEATS]: () => initialState,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
