import { createReducer } from 'utils/reduxHelpers';
import { AUTH, AGENDA } from 'redux/actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

const initialState = null;

const handlers = {
  [AGENDA.SET_AGENDAS]: (_, action) => action.payload,
  [AGENDA.UPDATE_AGENDA]: (state, action) => {
    const clonedState = cloneDeep(state);
    const index = clonedState.findIndex((item) => item.id === action.payload.agenda_id);
    return set(clonedState, index, {
      ...clonedState[index],
      agenda: action.payload,
    });
  },
  [AGENDA.UPDATE_ORGANIZER_CONTACTS]: (state, action) => {
    return state.map((event) => {
      return {
        ...event,
        organizer: {
          ...(event.organizer || {}),
          ...(action.payload.find((c) => event.organizer && event.organizer?.email === c.email) ||
            {}),
        },
      };
    });
  },
  [AGENDA.RESET_AGENDAS]: () => initialState,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
