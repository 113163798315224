import { useEffect } from 'react';

// Load Services
import { AnalyticsService } from 'services/AnalyticsService';

// Load Hooks
import { useHistory, useLocation } from 'react-router-dom';

// Load Configs
import { reg } from 'configs';

// Load Vendors
import { extractUUIDFromString } from 'utils/appHelpers';

const previousPage = {};

const reduceSensitiveData = (pathname = '') => {
  if (reg.magicCodeReg.test(pathname)) return '/mfa/magic_code';
  const reducedPath = pathname
    ?.split('/')
    ?.map((chunk) => {
      if (extractUUIDFromString(chunk)) {
        chunk = 'transcript_id';
      }
      return chunk;
    })
    ?.join('/');
  return reducedPath;
};

const useRoutingListener = () => {
  const history = useHistory();
  const location = useLocation();

  const trackPageview = ({ pathname, search }, prev = {}) => {
    if (
      pathname &&
      pathname !== '/' &&
      pathname !== prev.pathname &&
      !reg.ignorePageviewEventForUrls.test(pathname)
    ) {
      const queryParams = new URLSearchParams(search);
      const queryObj = [...queryParams].reduce((acc, [key, value]) => {
        if (key.startsWith('tr_')) acc[key] = value;
        return acc;
      }, {});

      pathname = reduceSensitiveData(pathname);

      const data = { page: pathname, query: queryObj };
      AnalyticsService.sendAction('pageview', data);
      AnalyticsService.sendEvent(AnalyticsService.events.pageview, { data });
    }
  };

  useEffect(() => {
    const { pathname, search } = location;
    trackPageview({ pathname, search });
    previousPage.pathname = pathname;

    const unregisterListener = history.listen(async (next) => {
      trackPageview(next, previousPage);
      previousPage.pathname = next.pathname;
    });
    return () => unregisterListener();
  }, []);
};

export default useRoutingListener;
