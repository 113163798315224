// Load vendors
import { combineReducers } from 'redux';

// Load reducers
import isAuthenticated from './reducers/isAuth';
import account from './reducers/account';
import accounts from './reducers/user/accounts';
import seats from './reducers/user/seats';
import waitlist from './reducers/user/waitlist';
import blackList from './reducers/user/blackList';
import meetings from './reducers/user/meetings';
import agendas from './reducers/user/agendas';
import activities from './reducers/user/activities';
import preferences from './reducers/user/preferences';
import records from './reducers/user/records';
import permissions from './reducers/user/permissions';
import domains from './reducers/user/domains';
import coWorkers from './reducers/user/coWorkers';
import resources from './reducers/resources';
import plans from './reducers/billing/plans';
import invoices from './reducers/billing/invoices';
import billing from './reducers/billing/billing';
import insightsData from './reducers/insight/data';
import insightsFilters from './reducers/insight/filter';
import audioDevices from './reducers/user/audioDevices';
import transcripts from './reducers/user/transcripts';
import experiments from './reducers/user/experiments';
import analyticsDashboardActiveTab from './reducers/user/analyticsDashboardActiveTab';
import installationMeta from './reducers/user/installationMeta';
import vocabulary from './reducers/user/vocabulary';
import onboardingBannerOpen from './reducers/user/onboardingBannerOpen';
import availableTeams from './reducers/user/availableTeams';
import workspacePreview from './reducers/workspace/workspacePreview';
import talkingSpeaker from './reducers/player/talkingSpeaker';
import playerCurrentTime from './reducers/player/currentTime';

const rootReducer = combineReducers({
  isAuthenticated,
  account,
  accounts,
  seats,
  waitlist,
  blackList,
  permissions,
  domains,
  coWorkers,
  resources,
  billing,
  plans,
  invoices,
  preferences,
  records,
  insightsFilters,
  insightsData,
  audioDevices,
  transcripts,
  meetings,
  agendas,
  activities,
  experiments,
  analyticsDashboardActiveTab,
  installationMeta,
  vocabulary,
  onboardingBannerOpen,
  availableTeams,
  workspacePreview,
  talkingSpeaker,
  playerCurrentTime,
});

export default rootReducer;
