import { useEffect } from 'react';
import { getError, getQueryParam } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import { csrfValidationPattern } from 'configs/consts';
import { useTranslation } from 'react-i18next';

const useExternalError = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();

  const fetchError = async (grant) => {
    try {
      const response = await Api.get(`/error/${grant}`);
      enqueueSnackbar(getError(response.data), { variant: 'error' });
    } finally {
      history.replace(location.pathname);
    }
  };

  const initCheck = () => {
    const error_grant = getQueryParam('error_grant');
    if (!error_grant || error_grant === 'undefined') return;
    if (!csrfValidationPattern.test(error_grant)) {
      enqueueSnackbar(t('unsupported_symbols_in_parameter'), { variant: 'error' });
    } else {
      fetchError(error_grant);
    }
  };

  useEffect(initCheck, []);
};

export default useExternalError;
