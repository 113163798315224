import { createReducer } from 'utils/reduxHelpers';
import { AUTH, INSIGHT } from 'redux/actionTypes';

const initialState = {
  labels: [],
  summaryData: {},
  barChartData: [],
  linearChartData: [],
};

const handlers = {
  [INSIGHT.SET_SUMMARY_DATA]: (state, action) => {
    return {
      ...state,
      summaryData: action.payload?.summaryData,
    };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
