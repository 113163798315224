// Load vendors
import axios from 'axios';
// import i18n from 'i18next';

// Load configs
import { HOST } from 'configs/host';
import moment from 'moment';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: {
    common: {
      krisp_origin_timezone: moment().format('Z'),
      krisp_header_web_project: 'note',
    },
  },
  data: {},
});

// Set config defaults when creating the instance
export const BasicApi = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: {
    common: {
      Authorization: 'Basic admin:admin',
      krisp_header_web_project: 'note',
    },
  },
});

// Set config defaults when creating the instance
export const PublicApi = axios.create({
  baseURL: `${HOST.API.URL}/platform/v1/`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: {
    common: {
      krisp_origin_timezone: moment().format('Z'),
      krisp_header_web_project: 'note',
    },
  },
  data: {},
});

export const isAxiosError = axios.isAxiosError;

export const setAuthToken = (authToken) => {
  Api.defaults.headers.common['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
  PublicApi.defaults.headers.common['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};
