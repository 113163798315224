import { ACCESS_TOKENS } from 'configs';
import { CookieService } from './CookieService';
import * as Sentry from '@sentry/react';

import amplitudeABTestingService from './AmplitudeABTestingService';
import { getError, getErrorCode, waitForThirdPartyModule } from 'utils/appHelpers';
import { cookiesAreEnabled, cookieTypes } from 'initOneTrust';
import {
  generateAnonymousId,
  generateSessionId,
  getAmplitudeUserId,
  logAnalyticsEventsForAutomation,
} from 'utils/analyticsHelpers';
import store from 'redux/store';
import { PermissionService } from './PermissionService';

import createService from '@krispai/kr-amplitude-analytics/client';
import { Api } from 'utils/connectors';
import { GTagService } from './GTagService';

const { hasAccess } = PermissionService;

const setAnonymousId = () => {
  let anonymousId = CookieService.get('anonymousId');
  if (!anonymousId) {
    anonymousId = generateAnonymousId();
  }
  CookieService.set('anonymousId', anonymousId, 2000);
  AnalyticsService.pushToDataLayer({ event: 'anonymousId', anonymousId });
};

const setSessionIdCookie = (userId) => {
  setAnonymousId();
  const anonymousId = CookieService.get('anonymousId');
  let sessionId = CookieService.get('krispSessionId');
  if (!sessionId) {
    sessionId = generateSessionId();
  }
  CookieService.set('krispSessionId', sessionId, '');
  AnalyticsService.pushToDataLayer({
    event: 'sessionIDs',
    sessionIDs: `${sessionId},${userId},${anonymousId}`,
  });
};

function experimentsCallBackWrapper() {
  return AnalyticsService.pushToDataLayer(arguments);
}

function experimentsCallBack(variant, value) {
  AnalyticsService.pushToDataLayer({
    event: ACCESS_TOKENS.GA_EVENT_NAME,
    'krisp-event-category': 'Experiment',
    'krisp-event-action': value,
    'krisp-event-label': +variant === 0 ? 'Control' : `Variant ${variant}`,
  });
}

const getPlanNameAndEndTime = (account) => {
  const isTrial = hasAccess('user_mode_free_pro');
  const reverseTrialActive = hasAccess('reverse_trial_active');
  const reverseTrialEnded = hasAccess('reverse_trial_ended');
  const planName = account?.team?.plan?.toLowerCase();
  const endDate = account?.trial_end_seconds || 0;

  return {
    planName,
    isTrial,
    reverseTrialActive,
    reverseTrialEnded,
    endDate,
    isRevers: planName === 'free' && isTrial,
  };
};

const { trackEvent, trackFailure, init, setUserId, identify, Identify, Events } = createService(
  'account',
  Sentry.captureMessage,
);

export const initClient = init;

export const AnalyticsService = {
  events: Events,
  sendEvent: function (event, body = {}) {
    cookiesAreEnabled(cookieTypes.THIRD_PARTY).then((enabled) => {
      if (!enabled) return;
      if (!body?.data) body.data = {};

      // "transcript_id" represents the entire meeting id which is old. We need to send both keys "transcript_id" and "meeting_id" for backward compatibility and not break the analytics dashboards. We'll remove "transcript_id" in the future (after 6 months - 1 year from this release)
      if (body.data.meeting_id) body.data.transcript_id = body.data.meeting_id;

      let hasError = null;
      if (event === Events.failure) {
        const failedEvent = body.data.failed_event;
        body.data.failed_event = failedEvent?.name;
        hasError = trackFailure(failedEvent, body);
      } else {
        hasError = trackEvent(event, body);
      }
      GTagService.sendGA4Event(event.name, body.data);
      logAnalyticsEventsForAutomation({ event: event?.name, body });
      if (hasError instanceof Error) {
        Sentry.captureException(hasError);
      }
    });
  },
  trackFailure: function (failedEvent, err = null, data = {}) {
    this.sendEvent(this.events.failure, {
      data: {
        failed_event: failedEvent,
        reason: getErrorCode(err) || 'UNHANDLED_ERROR_CODE',
        error_message: getError(err) || 'n/a',
        failed_event_data: { data },
      },
    });
  },
  sendEvents: function (events, err) {
    events.forEach(({ event, ...body }) => this.sendEvent(event, body, err));
  },
  pushToDataLayer(body) {
    cookiesAreEnabled(cookieTypes.THIRD_PARTY).then((enabled) => {
      if (!enabled) return;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(body);
    });
  },
  pushUser: function (account) {
    const userId = account?.id || 0;
    cookiesAreEnabled(cookieTypes.FIRST_PARTY).then((enabled) => {
      if (!enabled) return;
      CookieService.set('userId', userId);
      setSessionIdCookie(account?.id || 0);
      GTagService.setGA4UserId(userId);
      this.setAccountStateCookies(account);
    });
    cookiesAreEnabled(cookieTypes.THIRD_PARTY).then((enabled) => {
      if (!enabled) return;
      if (userId) {
        setUserId(getAmplitudeUserId(account));
      }
      this.amplitudeSetUserProperties(account);
      amplitudeABTestingService.experiment?.fetch();
      this.pushToDataLayer({ event: 'userId', userId });
    });
    if (userId) {
      Sentry.setUser({ id: userId });
    }
  },
  setAccountStateCookies(account) {
    if (!account) return;
    let cookieValue;
    if (!account?.has_used_app) {
      cookieValue = 'showDownload';
    } else {
      cookieValue = account.team?.seat?.role === 'Admin' ? 'teamAdmin' : 'teamMember';
    }
    CookieService.set('krisp_account_type', cookieValue);
  },
  getOptimizeVariant(activeEventKey, variantKey, successCallback, errorCallback) {
    if (activeEventKey !== '') {
      this.pushToDataLayer({ event: activeEventKey });
    }
    waitForThirdPartyModule('google_optimize')
      .then(() => successCallback(window[variantKey]))
      .then(() =>
        experimentsCallBackWrapper('event', 'optimize.callback', {
          callback: (name, value) => experimentsCallBack(name, value),
        }),
      )
      .catch(errorCallback);
  },
  amplitudeSetUserProperties: (account) => {
    if (!account) return;
    const identifyObj = new Identify();
    const { isTrial, reverseTrialActive, reverseTrialEnded, isRevers, endDate } =
      getPlanNameAndEndTime(account);

    identifyObj.set('user_role', account?.team?.seat?.role?.toLowerCase());
    identifyObj.set('plan', account?.team?.plan?.toLowerCase());
    identifyObj.set('team_seat_count', account?.team?.seats?.count);
    identifyObj.set('team_type', account?.team?.type?.toLowerCase());
    identifyObj.set('trial', isTrial);
    identifyObj.set('revers_trial', isRevers);
    identifyObj.set('revers_trial_active', reverseTrialActive);
    identifyObj.set('revers_trial_ended', reverseTrialEnded);
    identifyObj.set('trial_ends_in', endDate);
    identify(identifyObj);
  },
  amplitudeAddUserProperty: (key, value) => {
    cookiesAreEnabled(cookieTypes.THIRD_PARTY).then((enabled) => {
      if (!enabled || !key || value === undefined) return;
      const identifyObj = new Identify();
      identifyObj.set(key, value);
      identify(identifyObj);
    });
  },
  cleanUserEvents: function () {
    this.pushToDataLayer({ event: 'userId', userId: 0 });
  },
  setSessionIdCookie,
  sendAction: async (action, data = {}, isFail = false) => {
    return new Promise((resolve, reject) => {
      try {
        const state = store.getState();
        if (state?.isAuthenticated) {
          // Note: Workaround for the issue of sending analytics events in Safari Browser
          setTimeout(() => {
            Api.post(`/actions/${action}`, { data, is_fail: isFail }).then(resolve).catch(reject);
          }, 10);
        }
      } catch (error) {
        Sentry.captureException(error);
        reject(error);
      }
    });
  },
};
