import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { snackbarSettings } from './configs';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import 'react-dates/initialize';
import './lang';
import 'scss/app.scss';
import App from './app';
import InlineScripts from 'InlineScripts';
import AppLoading from 'shared/components/loaders/AppLoading';
import ErrorBoundary from 'shared/components/layout/ErrorBoundary';
import RouteTitle from 'shared/components/layout/RouteTitle';
import initSentry from './initSentry';
import initOneTrust from './initOneTrust';
import initHotjar from './initHotjar';
import ToastWrapper from 'shared/components/vendor/ToastWrapper';
import { BreadcrumbProvider } from '@krispai/kr-ui-components';
import { SocketProvider } from 'providers/SocketProvider';

initSentry();
initOneTrust();
initHotjar();

render(
  <InlineScripts>
    <Provider store={store}>
      <SocketProvider>
        <SnackbarProvider
          {...snackbarSettings}
          Components={{
            success: ToastWrapper,
            info: ToastWrapper,
            warning: ToastWrapper,
            error: ToastWrapper,
          }}
        >
          <Suspense fallback={<AppLoading />}>
            <ErrorBoundary>
              <Router>
                <BreadcrumbProvider>
                  <RouteTitle>
                    <App />
                  </RouteTitle>
                </BreadcrumbProvider>
              </Router>
            </ErrorBoundary>
          </Suspense>
        </SnackbarProvider>
      </SocketProvider>
    </Provider>
  </InlineScripts>,
  document.getElementById('root'),
);
