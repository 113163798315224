import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';

const initialState = {};

const handlers = {
  [USER.SET_PREFERENCES]: (state, action) => {
    const { branch, preferences } = action.payload;
    delete state[branch];
    const clonedState = cloneDeep(state);
    clonedState[branch] = preferences;
    return clonedState;
  },
  [USER.UPDATE_PREFERENCES_PARTIALLY]: (state, action) => {
    const clonedState = cloneDeep(state);
    const { branch, preferences } = action.payload;
    clonedState[branch] ||= {};
    const branchPreferences = clonedState[branch];
    Object.entries(preferences).forEach(([path, value]) => {
      set(branchPreferences, path, value);
    });
    return clonedState;
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
