/* eslint-disable no-useless-escape */
import React from 'react';
import moment from 'moment';

// Load Vendors
import i18n from 'i18next';
import FileSaver from 'file-saver';
import URI from 'urijs';
import { Trans } from 'react-i18next';
import { dateFormat } from './dateHelpers';
import { isEmail } from './validationHelpers';
import { getENV, reg, speakerRegexpWithGroup } from '../configs';
import { sanitizeStr } from './domHelpers';
import isArray from 'lodash/isArray';
import { buildTranscriptName, getMeetingDateInfo, getPersonInitials } from './meetingNotesHelpers';

// Load Components
import Tooltip from 'shared/components/vendor/Tooltip';

export const duplicate = (obj) => JSON.parse(JSON.stringify(obj));

export const JSONParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const getError = (err, withoutCode) => {
  if (!err) return null;
  const { response, data, message } = err;
  if (response) {
    const { error_code, error_sub_code, message_code } = response.data;
    const code = error_sub_code || error_code || '';
    const msg = i18n.t([`error.${code.toLowerCase()}`, 'error.unspecific']);
    const msg_code = message_code && !withoutCode ? ` | CODE: ${message_code}` : '';
    return `${msg} ${msg_code}`;
  }
  if (data && data.message) {
    return data.message;
  }
  if (message) {
    return message;
  }
  return err;
};

// For old browsers
const _getSearchParamForOldBrowser = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getQueryParam = (key, defaultValue = '') => {
  const browser = detectBrowser();
  const isOldBrowser = browser === 'other';
  let param;
  if (isOldBrowser) {
    param = _getSearchParamForOldBrowser(key);
  } else {
    const url = new URL(window.location.href);
    param = url.searchParams.get(key);
  }
  const result = param || defaultValue;
  return result ? String(result) : defaultValue;
};

export const buildURL = (url, params) => {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }
  const falsyValues = [null, undefined, ''];
  Object.keys(params).forEach((key) => {
    if (falsyValues.includes(params[key])) delete params[key];
  });
  const searchParams = new URLSearchParams(params);
  return `${url}?${searchParams}`;
};

export const buildLocalURL = (params) => {
  const searchParams = new URLSearchParams(params);
  return `${window.location.pathname}?${searchParams}`;
};

export const buildSearchParamsForOldBrowsers = (search) => {
  const existingSearch = window.location.search
    ? window.location.search
        .replace('?', '')
        .split('&')
        .reduce((acc, item) => {
          const [key, value] = item.split('=');
          acc[key] = value;
          return acc;
        }, {})
    : {};

  const url = [];

  Object.keys(search).forEach((key) => {
    if (!search[key]) {
      delete existingSearch[key];
    } else {
      existingSearch[key] = search[key];
    }
  });

  Object.keys(existingSearch).forEach((key, index) => {
    if (index === 0) {
      url.push('?');
    } else {
      url.push('&');
    }
    url.push(`${key}=${existingSearch[key]}`);
  });

  return url.join('');
};

export const isIE = () => {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11
  return msie > 0 || trident > 0;
};

export const calcPercent = (number, percent) => (number * percent) / 100;

export const calcDiscountPercent = (price, discountPrice) =>
  ((price - discountPrice) * 100) / price;

export const isMobileOrTablet = () => {
  let check = false;
  // eslint-disable-next-line
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const getCaptcha = (action = 'send_email') => {
  return new Promise((resolve, reject) => {
    if (window.grecaptcha === undefined) {
      reject('reCAPTCHA failed');
    }
    try {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          // eslint-disable-next-line spellcheck/spell-checker
          .execute('6LfI5A4cAAAAAJK0YpOfADOVKOzZW2XB55MFmSWM', { action })
          .then(resolve, () => {
            reject('reCAPTCHA failed');
          })
          .catch(() => reject('reCAPTCHA failed'));
      });
    } catch (err) {
      reject('reCAPTCHA failed');
    }
  });
};

export const getErrorCode = (err) => {
  if (!err) return null;
  const { response } = err;
  if (!response || !response.data) return null;
  const { error_code } = response.data;
  return error_code;
};

export const getUserStatus = (user) => {
  if (!user) return null;
  const { step_status } = user;
  switch (step_status) {
    case 'step_a_active':
      return 'active';
    case 'step_b_pending_login':
      return 'pending';
    case 'step_c_invited':
      return 'invited';
    default:
      return null;
  }
};

export const getLastAppUsageDate = (date, status) => {
  if (!date) {
    return status === 'active' ? (
      <Tooltip title={<Trans i18nKey='note_explanation_not_active' />}>
        <Trans i18nKey='note_never' />
      </Tooltip>
    ) : (
      <span className='text-gray-500'>{<Trans i18nKey='label_pending' />}</span>
    );
  }
  return <Trans i18nKey='last_activity_date'>{{ date: dateFormat(date, 'DD MMM, YYYY') }}</Trans>;
};

export const bindDuration = (duration) => {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 - hours * 60);
  const seconds = Math.floor(duration % 60);
  const round = (val) => (val < 10 ? `0${val}` : val);
  return `${hours ? round(hours) + ':' : ''}${round(minutes)}:${round(seconds)}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!bytes || bytes <= 0) return '0 MB';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let valueInMB = bytes / (k * 1024);
  if (valueInMB < 1) {
    return `${parseFloat(valueInMB).toFixed(3)} MB`;
  }
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatBytesToMB = (bytes) => {
  if (!bytes || bytes === 0) return 0;
  const megabyte = 1024 * 1024;
  return parseFloat((bytes / megabyte).toFixed(2));
};

export const formatBytesToGB = (bytes, decimals = 2) => {
  if (!bytes || bytes === 0) return '0 GB';
  const gigabyte = 1024 * 1024 * 1024; // 1 GB in bytes
  const dm = decimals < 0 ? 0 : decimals;
  return parseFloat((bytes / gigabyte).toFixed(dm)) + ' GB';
};

export const getUserSteps = (data) => {
  const { user, join_date } = data;
  const { invitation, last_app_usage } = user;
  const steps = [
    { name: 'step_invitation_received' },
    { name: 'step_invitation_accepted' },
    { name: 'step_logged_in' },
  ];

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    step.isDate = true;
    if (invitation && i === 0) {
      if (invitation.expired) {
        step.description = 'note_invite_expired';
        step.hasError = true;
        step.isDate = false;
      } else {
        step.description = invitation.date;
      }
      steps[i] = step;
    } else if (i === 1) {
      step.description = join_date;
    } else if (last_app_usage && i === 2) {
      step.description = last_app_usage.date;
    }
  }

  return steps;
};

export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1);

export const getPageTitle = (str) => {
  const customTitles = {
    '/login/1': 'Login',
    '/app/joined': 'Join',
    '/signin/sso': 'Sign in • SSO',
    '/invite': 'Invite',
    '/call-summary': 'Call Summary',
    '/oauth2/authorize': 'Authorize',
  };
  const ignoreCases = ['n', 't', 'a'];

  if (customTitles[str]) {
    return customTitles[str];
  }
  if (/^\/invite\/[\w\d]{25,}/.test(str.toLowerCase())) return customTitles['/invite'];
  if (/^\/call-summary\/[\w\d]{8,}/.test(str.toLowerCase())) return customTitles['/call-summary'];

  return str
    .replace(/-/gi, ' ')
    .replace(reg.uuidV4Reg, '')
    .split('/')
    .filter((v) => !ignoreCases.includes(v))
    .splice(1)
    .map(capitalize)
    .join(' • ');
};

export const downloadFile = (blob, name) => FileSaver.saveAs(blob, name);

export const downloadExternalFile = (url, fileName = null) => {
  const link = document.createElement('a');
  link.href = url;
  if (fileName) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyToClipboard = (text = '', callback) => {
  const textField = document.createElement('textarea');
  textField.value = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  callback && callback();
};

const constructSpeakers = (data, speakers = {}) => {
  return data.replace(speakerRegexpWithGroup, (match, speakerIndex) => {
    const person = speakers[speakerIndex]?.person;
    if (!person || (!person.first_name && !person.email)) return match.replace(/\{\{|\}\}/g, '');
    return getPersonInitials(person);
  });
};

export const copyRichTextToClipboard = (data = '', speakers = {}, callback) => {
  try {
    const isSafari = detectBrowser() === 'safari';
    const dataWithSpeakers = constructSpeakers(data, speakers);
    const sanitizedData = dataWithSpeakers; // Making {{Speaker_X}} to Speaker_X
    const textHTML = sanitizedData;
    const textPlain = textHTML.replace(/<li>/g, '- ').replace(/<[^>]*>/g, '');
    if (isSafari) {
      copyToClipboard(textPlain);
    } else {
      const handler = (e) => {
        e.clipboardData.setData('text/html', textHTML);
        e.clipboardData.setData('text/plain', textPlain);
        e.preventDefault();
      };
      document.addEventListener('copy', handler);
      document.execCommand('copy');
      document.removeEventListener('copy', handler);
    }
    callback && callback();
  } catch (err) {
    console.error('Failed to copy text: ', err);
  }
};

export const scrollTo = (identifier = null, top = 0, left = 0, behavior = 'smooth') => {
  (identifier ? document.querySelector(identifier) : window)?.scrollTo({ top, left, behavior });
};

export const bindNum = (val = 0, fix = 2) =>
  Math.round(Number(val) * Math.pow(10, fix)) / Math.pow(10, fix);

export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'win';
  } else if (/android/i.test(userAgent)) {
    os = 'android';
  } else if (!os && /linux/i.test(platform)) {
    os = 'linux';
  }

  return os;
};

export const bindDurationHours = (duration, op = Math.floor) => {
  if (!duration) return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  const seconds = duration % 60;
  const minutes = op((duration / 60) % 60);
  const hours = op(duration / 60 / 60);
  const days = op(duration / 60 / 60 / 24);
  return { days, hours, minutes, seconds };
};

export const formatDuration = (duration, op) => {
  const { days, hours, minutes, seconds } = bindDurationHours(duration, op);
  if (days > 0) {
    return pluralize(days, 'day');
  } else if (hours > 0) {
    return pluralize(hours, 'hour');
  } else if (minutes > 0) {
    return pluralize(minutes, 'min');
  } else {
    return pluralize(seconds, 'second');
  }
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let dates = [];

  for (let m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
    dates.push({ date: m.format('YYYY-MM-DD'), meetingTime: 0, talkTime: 0 });
  }

  return dates;
};

export const modifyTeams = (teams = []) => {
  if (!teams || !teams.length) return [];
  const modifiedTeams = teams.map((item) => {
    return item;
  });
  return modifiedTeams;
};

export const allowEnvs = (...envs) => {
  const env = getENV();
  return envs.includes(env);
};

export const denyEnvs = (...envs) => {
  return !allowEnvs(...envs);
};

export const normalizeStr = (str) => (str || '').replace(/\s\s+/g, ' ').trim();

export const pluralize = (count, noun, suffix = 's', gap = ' ') =>
  `${count}${gap}${noun}${count !== 1 ? suffix : ''}`;

export const bindRangeDuration = (
  mode = {},
  valuesOnly,
  addBonusMultiplier,
  removeBonusMultiplier = false,
) => {
  const { template = { range: 0, balance: 0, corporate_bonus: 1 } } = mode;
  const multiplier = (addBonusMultiplier ? template.corporate_bonus : 1) ?? 1;
  const divider = (removeBonusMultiplier ? template.corporate_bonus : 1) ?? 1;
  const mins = Math.round(template.balance / 60) * multiplier;
  const minsWithoutBonus = Math.round(template.balance / 60 / divider);
  const duration = mins ? pluralize(mins, 'min') : '0 mins';
  const rangeDays = template.range / 60 / 60 / 24;
  const rangeName = rangeDays >= 7 ? 'week' : 'day';

  if (valuesOnly) return { mins, duration, rangeDays, rangeName, minsWithoutBonus };
  return `${duration} a ${rangeName}`;
};

export const extractEmailDomain = (email = '') => email.split('@')[1];

export const getBooleanValue = (value) => {
  if (typeof value === 'string') return value === 'on';
  return !!value;
};

export const detectBrowser = () => {
  let { userAgent, vendor } = window.navigator;
  let browser = 'other';

  if (typeof InstallTrigger !== 'undefined') {
    browser = 'firefox';
  } else if (/Opera|OPR\//.test(navigator.userAgent)) {
    browser = 'opera';
  } else if (/Edg/.test(userAgent)) {
    browser = 'edge';
  } else if (/Chrome/.test(userAgent) && /Google Inc/.test(vendor) && !/Edg/.test(userAgent)) {
    browser = 'chrome';
  } else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    browser = 'safari';
  }

  return browser;
};

export const makeEmailShorter = (email, limit = 10) => {
  if (!email || !isEmail(email)) return;
  const splitted = email.split('@');
  const shortPrefix = splitted[0].substring(0, limit);
  const dots = shortPrefix.length < splitted[0].length ? '...' : '';
  return `${shortPrefix}${dots}@${splitted[1]}`;
};

export const waitForThirdPartyModule = (module, extraCheck = null) => {
  let counter = 0;
  const checkForModule = () => {
    const isModuleDefined = !!window[module];
    if (!isModuleDefined) return false;
    if (isModuleDefined && !extraCheck) return true;
    return extraCheck(window[module]);
  };
  return new Promise((resolve, reject) => {
    if (checkForModule()) {
      resolve();
      return;
    }
    const interval = setInterval(() => {
      counter++;
      if (checkForModule()) {
        clearInterval(interval);
        resolve();
      } else if (counter > 50) {
        clearInterval(interval);
        reject(`Unable to load ${module}`);
      }
    }, 100);
  });
};

export const extractUUIDFromString = (str = '') => str.match(reg.uuidV4Reg)?.[0];

export const cleanAppName = (str = '') => {
  // remove unwanted characters
  str = str.replace(/[^a-zA-Z. ]/g, '').toLowerCase();

  // array of app names and their regex
  const apps = [
    { name: 'zoom', regex: /(zoom|us)/ },
    { name: 'skype', regex: /(skype|com)/ },
    { name: 'webex', regex: /(webex|cisco)/ },
    { name: 'chrome', regex: /(chrome|com)/i },
    { name: 'meet', regex: /(meet|google)/ },
    { name: 'discord', regex: /(discord|com)/ },
    { name: 'firefox', regex: /(firefox|org)/ },
    { name: 'hangouts', regex: /(hangouts|com)/ },
    { name: 'slack', regex: /(slack|com)/ },
    { name: 'teams', regex: /(teams|com)/ },
    { name: 'general', regex: /(general)/ },
  ];

  let match = apps.find((app) => app.regex.test(str));
  return match ? match.name : 'general';
};

export const bindStartTime = (time) => {
  if (typeof time === 'string' && /:/.test(time)) return time;
  return bindDuration(time);
};

export const convertStringToHTML = (text = '') => {
  if (text.trim()?.[0] === '<') {
    return text;
  }
  let lines = text.split('\n');
  let html = '';
  let inList = false;
  for (let line of lines) {
    line = line.trim();
    if (line.startsWith('• ') || line.startsWith('- ') || line.match(/^\d\. /)) {
      if (!inList) {
        html += '<ul>\n';
        inList = true;
      }
      html += '<li>' + line.substring(2) + '</li>\n';
    } else {
      if (inList) {
        html += '</ul>\n';
        inList = false;
      }
      html += line + '\n';
    }
  }
  if (inList) {
    html += '</ul>\n';
  }
  return sanitizeStr(html);
};

export const arrayToString = (items = []) => {
  if (typeof items === 'string') return items;
  if (!items.length) return '';
  const processListItem = (item) => {
    let str = item.title.replace();
    if (item.assignee) {
      const assigneeLine = item.assignee?.first_name
        ? ` - ${item.assignee.first_name}`
        : item.assignee?.email
        ? ` - ${item.assignee.email}`
        : '';
      str += assigneeLine;
    }
    if (item.due_date) {
      const dueDateLine =
        item.due_date && item.due_date !== 'N/A'
          ? `, Due: ${moment(item.due_date).format('MMM DD')}`
          : '';
      str += dueDateLine;
    }
    return str;
  };
  const hasOnlyTitles = items.every((item) => !!item.title && !item.description);
  const hasOnlyDescription = items.every((item) => !!item.description && !item.title);
  let data = [];
  if (hasOnlyTitles) {
    data = [
      {
        header: null,
        list: items.map((item) => processListItem(item)),
      },
    ];
  } else if (hasOnlyDescription) {
    data = [
      {
        header: null,
        list: items
          .map((item) => item.description)
          .map((description) => description.split('\n'))
          .flat()
          .map((description) => processListItem({ title: description })),
      },
    ];
  } else {
    data = items.map((item) => ({
      header: item.title,
      list:
        item.description
          ?.split('\n')
          ?.map((description) => processListItem({ title: description })) || [],
    }));
  }
  const htmlData = data
    .map((block) => {
      let str = '';
      if (block.header) str += `<p><strong>${block.header}</strong></p>\n`;
      str += `<ul>${block.list.map((item) => `<li>${item}</li>`).join('\n')}</ul>`;
      return str;
    })
    .join('\n\n<br/>');
  return htmlData;
};

export const buildNotesToCopy = (text, data, ignoreHeaders) => {
  if (isArray(text)) text = arrayToString(text);
  if (data) {
    const _sanitize = (s) => sanitizeStr(s).replace(/[><'"]/g, '');
    const { formattedDate, formattedStartTime, formattedDuration } = getMeetingDateInfo(
      data.started_at || data.created_at,
      data.duration,
    );
    const title = _sanitize(data.name);
    // eslint-disable-next-line spellcheck/spell-checker
    const shareURL = `${_sanitize(buildNotesShareURL(title, data.id))}?nopreview`;
    const titleP = ignoreHeaders
      ? ''
      : `<p><a href='${shareURL}' target='_blank'>📝 <strong>${title} Notes</strong></a></p>\n`;
    const dateP = ignoreHeaders
      ? ''
      : `<p>🕞 Started at ${formattedStartTime} on ${formattedDate}, lasted ${formattedDuration}</p><br>\n\n`;
    text = `${titleP}${dateP}${text}`;
  }
  return text;
};

export const buildNotesToCopySeparately = (items, transcript, isShared) => {
  return items.map(([title, content]) => {
    if (!content) return null;
    const text = buildNotesToCopy(content, transcript, isShared);
    return `<strong>${title}</strong>\n<br>${text}`;
  });
};

export const purifyNotesContents = (contents) => {
  const purifyString = (str) => {
    if (!str) return '';
    return str?.replace(/<[^>]*>|&nbsp;|&#.*?;|[\s\n]|[^a-zA-Z0-9]/g, '') || '';
  };

  return contents.map((content) => {
    if (typeof content === 'string') {
      return purifyString(content);
    } else {
      return content.reduce((acc, item) => {
        const { title = '', description = '' } = item;
        const pureTitle = purifyString(title);
        const pureDescription = purifyString(description);
        acc += `${pureTitle}${pureDescription}`;
        return acc;
      }, '');
    }
  });
};

export const buildNotesShareURL = (title = '', transcriptId) => {
  title = buildTranscriptName(title);
  return `${window.location.origin}/n/${title}--${transcriptId}`;
};

export const buildMeetingShareURL = (title = '', transcriptId, type = 't') => {
  title = buildTranscriptName(title);
  return `${window.location.origin}/${type}/${title}--${transcriptId}`;
};

export const groupArrayByField = (rows = [], field) => {
  const groupedRows = rows.reduce((acc, item) => {
    const key = moment(item[field]).format('ddd, DD MMM');
    if (!acc[key]) acc[key] = { [field]: item[field], rows: [] };
    acc[key].rows.push(item);
    return acc;
  }, {});
  return Object.values(groupedRows);
};

export const isSubdomain = (domain) => {
  const withProtocol = new URI(domain).protocol('https').toString();
  return !!new URI(withProtocol).subdomain();
};

export const getFullName = (firstName, lastName) => {
  return `${firstName || ''} ${lastName || ''}`.trim();
};

export const getPeriodEndSeconds = (date) => moment(date).diff(moment(), 'seconds');

export const queryToNumericArray = (key) => {
  const queryValue = getQueryParam(key);
  return (queryValue && queryValue?.split(',').map(Number)) || [];
};

export const middleTruncate = (string, maxLength) => {
  if (!string) return string;
  if (string?.length <= maxLength) {
    return string;
  }

  const middle = Math.floor(maxLength / 2);
  const leftHalf = string.slice(0, middle);
  const rightHalf = string.slice(-middle);

  return leftHalf + '...' + rightHalf;
};

export const someFormElementFocused = () => {
  const focusedElement = document.activeElement;

  // Check if the focused element is an input element (e.g., input, textarea, select)
  return (
    focusedElement instanceof HTMLInputElement ||
    focusedElement instanceof HTMLTextAreaElement ||
    focusedElement instanceof HTMLSelectElement
  );
};

export const ordinalSuffix = (number) => {
  number = Number(number);
  // Return examples: 1st, 2nd, 3rd, 4th, 5th, 6th, 7th, 8th ...
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

export const formatNumberAbbreviation = (number, prefix) => {
  if (number < 100) {
    return `${prefix}${number}`;
  } else if (number < 1000) {
    return '99+';
  } else if (number < 1000000) {
    return `${prefix}${(number / 1000).toFixed(0)}K`;
  } else {
    return `${prefix}${(number / 1000000).toFixed(0)}M`;
  }
};

// Remove empty string values and empty arrays from the body
export const cleanupEmptyValues = (body) => {
  const result = { ...body };
  Object.keys(result).forEach((key) => {
    if (result[key] === '' || (Array.isArray(result[key]) && !result[key].length)) {
      delete result[key];
    }
  });
  return result;
};

export const generateRid = () => {
  return Date.now().toString();
};

export const placeCursorInNode = (node, offset = 0) => {
  try {
    const range = document.createRange();
    const sel = window.getSelection();

    const textNode = node.firstChild;
    range.setStart(textNode || node, Math.min(offset, textNode ? textNode.length : 0));

    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);
  } catch (error) {
    console.error(error);
  }
};

export const openInNewTab = (url, cb) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
  cb && cb();
};

export const getLocalURL = (url = '') => {
  if (!url) return;
  const { pathname, search } = new URL(url);
  return pathname + search;
};
