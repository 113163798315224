import { createReducer } from 'utils/reduxHelpers';
import { BILLING, AUTH } from 'redux/actionTypes';

const initialState = null;

const handlers = {
  [BILLING.SET_BILLING]: (state, action) => {
    const { plans } = action.payload || {};

    return {
      ...action.payload.data,
      upgrades: plans,
    };
  },
  [BILLING.UPDATE_UNASSIGNED_SEATS_COUNT]: (state, action) => {
    return {
      ...state,
      seats: {
        ...state?.seats,
        unassignedSeatsCount: action.payload,
        used: state?.seats?.used + action.payload,
      },
    };
  },
  [BILLING.REMOVE_BILLING]: () => initialState,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
