/* eslint-disable no-useless-escape */
/* eslint-disable spellcheck/spell-checker */
import { GENERAL } from 'configs';

const { WEBSITE, SUPPORT_WEBSITE, NEWS_WEBSITE, DOWNLOAD_DOMAIN, BASE_DOMAIN } = GENERAL;

export const EXTERNAL_LINKS = {
  csv_document_formatting: `${SUPPORT_WEBSITE}hc/en-us/articles/360012035240-Bulk-inviting-users-to-the-team-account-for-admins-`,
  csv_upload_requirements: '#.',
  app_setup_instructions: `${SUPPORT_WEBSITE}hc/en-us/sections/4550537903900-Krisp-with-other-apps`,
  teams: `${WEBSITE}remote-teams`,
  enterprise: `${WEBSITE}enterprises`,
  call_center: `${WEBSITE}call-center`,
  hr: `${WEBSITE}hd-voice`,
  plans: `${WEBSITE}pricing`,
  partners: `${WEBSITE}partners`,
  about: `${WEBSITE}about-us`,
  careers: '#.',
  in_news: `${WEBSITE}`,
  brand: `${WEBSITE}brand-guidelines`,
  blog: `${WEBSITE}blog`,
  tutorials: 'https://www.youtube.com/channel/UCAMZinJdR9P33fZUNpuxXtg/playlists',
  technology: `${WEBSITE}technology`,
  security: `${WEBSITE}security`,
  policy: `${WEBSITE}privacy-policy`,
  terms: `${WEBSITE}terms-of-use`,
  personal: `${BASE_DOMAIN}personal`,
  news: NEWS_WEBSITE,
  help: `${SUPPORT_WEBSITE}hc/en-us`,
  feedback_form: `https://yrugooer2xw.typeform.com/to/Ybnw83gH`,
  team_subscription: `${SUPPORT_WEBSITE}hc/en-us/articles/360020584200-Billing-logic-for-the-team-account`,
  team_subscription_cancellation: `${SUPPORT_WEBSITE}hc/en-us/articles/360012035440-What-happens-if-I-cancel-my-team-subscription-`,
  system_requirements: `${SUPPORT_WEBSITE}hc/en-us/articles/360011979299-System-requirements-for-Krisp`,
  download_app_win: `${DOWNLOAD_DOMAIN}/win`,
  download_app_mac: `${DOWNLOAD_DOMAIN}/mac`,
  website: WEBSITE,
  request_demo: `${WEBSITE}pricing/?contact=1`,
  safe_record_online_meetings: `${SUPPORT_WEBSITE}hc/en-us/articles/360021371800`,
  record_listening: `${SUPPORT_WEBSITE}hc/en-us/articles/360020925599-Listening-to-shared-recordings`,
  pricing: `${WEBSITE}pricing`,
  chat_resources: `${WEBSITE}chat-with-us?ajax=1&locale=en-US`,
  protocol_link: `${WEBSITE}hc/en-us/articles/4411971502226`,
  suspicious_sign_in_link: `${SUPPORT_WEBSITE}hc/en-us/articles/4411971502226-You-prevented-a-suspicious-sign-in-attempt`,
  device_config: `${SUPPORT_WEBSITE}hc/en-us/articles/360022135659-Configure-the-device-login-team-for-admins-`,
  getting_started: `${SUPPORT_WEBSITE}hc/en-us/categories/4550485140508-Using-Krisp`,
  enforce_SSO_link: `${SUPPORT_WEBSITE}hc/en-us/articles/4407835114770-Enforce-SSO-option-for-the-team-account-for-admins-`,
  krisp_versions_lifecycle: `${SUPPORT_WEBSITE}hc/en-us/articles/7756674622236`,
  update_krisp_app: `${SUPPORT_WEBSITE}hc/en-us/articles/5714948773660`,
  meeting_notes_faq: `${SUPPORT_WEBSITE}hc/en-us/articles/8326933081116-AI-Meeting-Assistant-FAQ#who-can-use-this--0-0`,
  bvc_compatible_devices_link:
    'https://help.krisp.ai/hc/en-us/articles/7270378194972#h_01G9EYFE61EJ13C6BGEEVTPW80',
  transcript_feedback_typeform: 'https://yrugooer2xw.typeform.com/to/sLrK8keu',
  meeting_notes_feedback_typeform: 'https://yrugooer2xw.typeform.com/to/rbCvLPV0',
  analytics_feedback_typeform: 'https://yrugooer2xw.typeform.com/to/xBKAdKg9',
  consent_doc:
    'https://docs.google.com/document/d/1wfSPYiER5JVRNPkQP11o5-66q0WFhZmDh4aTVrunMi8/edit?usp=sharing',
  feedback_transcript_contact_link: 'https://calendly.com/kshahnazaryan/experience-with-krisp',
  feedback_note_contact_link: 'https://calendly.com/kshahnazaryan/experience-with-krisp',
  feedback_action_items_contact_link: 'https://calendly.com/kshahnazaryan/experience-with-krisp',
  agenda_feedback_link: 'https://yrugooer2xw.typeform.com/to/DGp0YNZR',
  assigned_devices_info:
    'https://help.krisp.ai/hc/en-us/articles/7700179491484-Devices-page-in-Admin-Dashboard#h_01GBYTHS1WC1E9K5BWPGW7ZT2Q',
  app_configuration_help_link:
    'https://help.krisp.ai/hc/en-us/articles/360020620139-Team-Settings-page-in-Admin-Dashboard#h_01GB2V7MBA5AJH4HJP1CK813GG',
  max_version_help_link:
    'https://help.krisp.ai/hc/en-us/articles/4420088642460-Install-Krisp#h_01H3FMPQPEDBAEBM005Y2QGB8K',
  download: {
    mac: {
      stable: {
        standard: `download/2/mac?release_channel=standard&max_version=1.99.99`,
        enterprise: `download/2/mac?release_channel=enterprise&max_version=1.99.99`,
      },
      latest_intel: `download/2/mac?release_channel=standard&max_version=2.99.99&type=note`,
      latest_arm: `download/2/mac?release_channel=standard&max_version=2.99.99&package=package_arm&type=note`,
    },
    win: {
      stable: {
        standard: `download/2/win?release_channel=standard&max_version=1.99.99`,
        enterprise: `download/2/win?release_channel=enterprise&max_version=1.99.99`,
      },
      latest: `download/2/win?release_channel=standard&max_version=2.99.99&type=note`,
    },
  },
  ai_meeting_asistant: `${WEBSITE}ai-meeting-assistant/`,
  roi_tooltip_link: `${SUPPORT_WEBSITE}hc/en-us/articles/9629583598620`,
  calendar_connect_police: `${SUPPORT_WEBSITE}/hc/en-us/articles/10277892556828-Connecting-your-Calendar-to-Krisp`,
  pricing_contact: 'https://krisp.ai/pricing/?contact=1',
  bot_email: `${SUPPORT_WEBSITE}/hc/en-us/articles/12535172635804-Krisp-Bot-Beta#h_01HWQEE2DF66FNQMGSVHAEKWJ4`,
  integrations_survay: 'https://yrugooer2xw.typeform.com/to/rxlqphNF',
  integrations_learn_more: `${SUPPORT_WEBSITE}/hc/en-us/articles/14772270941852`,
  rate_on_trustpilot: 'https://www.trustpilot.com/evaluate/krisp.ai',
  integrations_upcoming_hubspot: 'https://yrugooer2xw.typeform.com/to/GaVLY9E5',
  integrations_upcoming_slack: 'https://yrugooer2xw.typeform.com/to/uJVgHfZ6',
  auto_share_meeting_link:
    'https://help.krisp.ai/hc/en-us/articles/10386573495196-Sharing-your-meetings#h_01J8553SC5H52MZEGMYND1RXC7',
};

export const supportEmail = 'support@krisp.ai';

export const seatsAdditionLimit = 150;

export const planCurrencies = {
  usd: '$',
  jpy: '¥',
};

export const YenPlansMapping = {
  proMonthly: 'proMonthlyYen',
  proAnnual: 'proAnnualYen',
  businessMonthly: 'businessMonthlyYen',
  businessAnnual: 'businessAnnualYen',
};

export const planRules = {
  proMonthly: ['pro', 'month', 'usd', 'default'],
  proAnnual: ['pro', 'year', 'usd', 'default'],
  businessMonthly: ['business', 'month', 'usd', 'default'],
  businessAnnual: ['business', 'year', 'usd', 'default'],
  maBusinessMonthly: ['ma_business', 'month', 'usd', 'default'],
  maBusinessAnnual: ['ma_business', 'year', 'usd', 'default'],

  proMonthlyYen: ['pro', 'month', 'jpy', 'default'],
  proAnnualYen: ['pro', 'year', 'jpy', 'default'],
  businessMonthlyYen: ['business', 'month', 'jpy', 'default'],
  businessAnnualYen: ['business', 'year', 'jpy', 'default'],
};

export const tableFilterItems = {
  lastActivityItems: [
    { value: 4, name: 'Recently' },
    { value: 3, name: 'Last 7 days' },
    { value: 2, name: 'Last 30 days' },
    { value: 1, name: 'More than 30 days' },
    { value: 0, name: 'Never' },
  ],
  statusItems: [
    { value: 2, name: 'Signed in with app', componentState: 'active' },
    { value: 1, name: 'Invitation accepted', componentState: 'pending' },
    { value: 0, name: 'Invitation sent', componentState: 'invited' },
  ],
  roleItems: [
    { value: 1, name: 'Admin' },
    { value: 0, name: 'User' },
  ],
  audioDeviceSettingItems: [
    { value: 0, name: 'Auto' },
    { value: 1, name: 'ON' },
    { value: 2, name: `User\'s choice` },
    { value: 3, name: 'OFF' },
  ],
};

export const userListForUserColumns = ['email', 'step_status'];

export const stripeFonts = [
  {
    family: 'Lexend',
    src: 'url(../asset/fonts/Lexend-Regular.ttf)',
    weight: '400',
  },
  {
    family: 'Lexend',
    src: 'url(../asset/fonts/Lexend-Medium.ttf)',
    weight: '500',
  },
  {
    family: 'Lexend',
    src: 'url(../asset/fonts/Lexend-Bold.ttf)',
    weight: '700',
  },
];

export const teamSettingsSupport = {
  force_microphone: [{ os: 'win', ver: '1.8.6' }],
  force_speaker: [{ os: 'win', ver: '1.40.5' }],
  update_available: [
    { os: 'win', ver: '1.1.4' },
    { os: 'mac', ver: '1.40' },
  ],
  auto_download: [{ os: 'win', ver: '1.44' }],
  release_channel: [
    {
      os: 'win',
      text: 'note_existing_users_would_need_to_download_and_reinstall_new_version',
    },
    {
      os: 'mac',
      text: 'note_existing_users_would_need_to_download_and_reinstall_new_version',
    },
  ],
  contact_support: [
    { os: 'win', ver: '1.21.1' },
    { os: 'mac', ver: '1.22.7' },
  ],
  report_problem: [
    { os: 'win', ver: '1.21.1' },
    { os: 'mac', ver: '1.22.7' },
  ],
  allow_widget: [
    { os: 'win', ver: '1.21.1' },
    { os: 'mac', ver: '1.22.7' },
  ],
  echo_cancellation: [{ os: 'win', ver: '1.18.4' }],
  virtual_background: [{ os: 'mac', ver: '1.23.7' }],
  pnc: [
    { os: 'win', ver: '1.30.7' },
    { os: 'mac', ver: '1.40' },
  ],
  ultrasonic: [{ os: 'win', ver: '1.38' }],
  nc_control: [{ os: 'win', ver: '1.32.3' }],
  optimized_cpu: [{ os: 'win', ver: '1.48' }],
  email_notification: [],
  talk_time_widget: [
    { os: 'win', ver: '1.37' },
    { os: 'mac', ver: '1.34' },
  ],
  show_speaker_toggle_in_widget: [{ os: 'win', ver: '1.49' }],
  call_started_with_mic_nc_on: [
    { os: 'win', ver: '1.34.4' },
    { os: 'mac', ver: '1.33.7' },
  ],
  call_started_with_mic_nc_off: [
    { os: 'win', ver: '1.34.4' },
    { os: 'mac', ver: '1.33.7' },
  ],
  mic_used_without_speaker: [
    { os: 'win', ver: '1.34.4' },
    { os: 'mac', ver: '1.33.7' },
  ],
  mic_used_outside_krisp: [
    { os: 'win', ver: '1.34.4' },
    { os: 'mac', ver: '1.33.7' },
  ],
  call_summary: [
    { os: 'win', ver: '1.35.2' },
    { os: 'mac', ver: '1.34.6' },
  ],
  nc_delay: [{ os: 'win', ver: '22.Q4.3' }],
  app_debug: [{ os: 'win', ver: '1.49' }],
  send_system_user_data: [{ os: 'win', ver: '22.Q4.2' }],
};

export const localUrlRedirections = {
  '/app/manage/records': { to: '/records', params: {} },
  '/app/manage/referral': { to: '/referral', params: {} },
  '/resource/refer_friend': { to: '/referral', params: {} },
  '/app/unlock': { to: '/billing-team/upgrade-to-pro' },
  '/app/report': { to: '/report' },
  '/login/sso': { to: '/signin/sso' },
};

export const reg = {
  agendaPageReg: /^\/agenda(s)?($|\/.*)/,
  agendaTabReg: /\/a\//,
  email: /^([+\w-]+(?:\.[+\w-]+)*)@((?:[+\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$/i,
  integer: /^(?:[1-9]\d*|0)$/i,
  zipCode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  domain: /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
  ignorePageviewEventForUrls: /^\/(team-settings|teams|users|devices)\/?$/,
  uuidV4Reg: /[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}/i,
  meetingNotesOrTranscript: /\/meeting-notes|\/n\/|\/t\//,
  meetingNotesPageReg: /\/meeting-notes|\/n\/|\/t\/|\/a\//,
  manageStoragePageReg: /\/manage-storage|\/n\/|\/t\//,
  magicCodeReg: /\/mfa\/[a-zA-Z0-9]{6}$/,
  link: /(https?:\/\/[^\s]+)/g,
  canadianPostalCodeReg:
    /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
  validWord: /[\p{L}-]+/gu,
  validTag: /^[\p{L}']+$/u,
};

export const csrfValidationPattern = /^[^./%&]*$/;

export const domainStateStatusMap = {
  initial: {
    appearance: 'filled',
    text: 'note_domain_verification_status_not_started',
  },
  active: {
    appearance: 'success',
    text: 'note_domain_verification_status_verified',
  },
  pending: {
    appearance: 'warning',
    text: 'note_domain_verification_status_pending',
  },
  fail: {
    appearance: 'danger',
    text: 'note_domain_verification_status_failed',
  },
};

export const audioDeviceStateMap = {
  0: 'auto',
  1: 'on',
  2: 'manual',
  3: 'off',
};

export const audioDeviceStateBackMap = {
  auto: 0,
  on: 1,
  manual: 2,
  off: 3,
};

export const avatarVariants = ['default', '1', '2', '3', '4', '5', '6', '7'];

export const generalAccessKeys = ['user', 'workspace', 'link'];

export const speakerRegexp = /{{Speaker_\d+}}/gi;

export const speakerRegexpWithGroup = /{{Speaker_(\d+)}}/gi;

export const MeetingNotesFeatures = {
  transcript: {
    lowConfidenceWordsHighlight: {
      enabled: true,
      minConfidence: Number(new URLSearchParams(window.location.search).get('lc_threshold') || 0.7),
      maxConfidence: 1,
    },
    rating: {
      showAfterLetters: Number(
        new URLSearchParams(window.location.search).get('rating_box_after') || 2000,
      ),
    },
  },
  meeting_notes: {
    action_items: {
      enabled: true,
      title: 'Action Items',
      prompt: 'action_items',
      priority_list: [
        {
          label: 'High',
          value: 3,
        },
        {
          label: 'Medium',
          value: 2,
        },
        {
          label: 'Low',
          value: 1,
        },
        {
          label: 'None',
          value: null,
        },
      ],
    },
    next_steps: {
      enabled: true,
      title: 'Next Steps',
      prompt: 'next_steps',
    },
    detailed_summary: {
      enabled: true,
      title: 'Outline',
      prompt: 'detailed_summary',
    },
    key_points: {
      enabled: true,
      title: 'Key Points',
      prompt: 'key_points',
    },
    agenda: {
      enabled: true,
      title: 'Agenda',
      prompt: 'agenda',
    },
  },
  recording: {
    enabled: true,
    highlighting: {
      enabled: true, // Enabled state of highlighting feature
      topGap: 50, // The gap to be left between the top of the container and the highlighted element when scrolling to it
      sensitivity: 60, // Player's time change detection interval
      autoScrolling: {
        enabled: true, // Enabled state of auto-scrolling feature
        disableOnManualScroll: true, // Disable auto-scrolling when the user scrolls manually
      },
    },
  },
  permissions: [
    {
      label: 'Can edit',
      key: 'edit',
      description: 'Can edit and share with others',
      resources: {
        transcript: 'edit',
        meeting_note: 'edit',
        recording: 'edit',
        agenda: 'edit',
      },
    },
    {
      label: 'Can view',
      key: 'view',
      description: 'Can view summary, transcript, audio recording, agenda',
      resources: {
        transcript: 'view',
        meeting_note: 'view',
        recording: 'view',
        agenda: 'view',
      },
    },
    {
      label: 'Can view summary',
      key: 'view_summary',
      description: 'Cannot view transcript, audio recording, agenda',
      resources: {
        transcript: false,
        meeting_note: 'view',
        recording: false,
        agenda: false,
      },
    },
  ],
  findPermissionByKey: function (key) {
    return MeetingNotesFeatures.permissions.find((permission) => permission.key === key);
  },
  findPermissionByResources: function (resources) {
    return MeetingNotesFeatures.permissions.find((permission) => {
      return Object.keys(resources).every((key) => permission.resources[key] === resources[key]);
    });
  },
};

export const couponKrispOff = 'krisp20off';

export const seatsManagementModalRoutes = [
  'add-seats',
  'remove-seats',
  'retry-payment',
  'three-ds',
];

export const changeStorageTypes = ['increase', 'decrease'];

export const invoicePageLimit = '6';

export const seatBulkRemoveLimits = {
  count: 100,
  assignedRatio: 0.3,
};

export const yearlyDiscountSettings = {
  daysAfterSignup: 11,
  daysAfterBannerClosed: 7,
};

export const STORAGE_STATUS_VARIANTS = {
  default: {
    status: 'default',
    text: 'default',
  },
  warning: {
    status: 'warning',
    text: 'default',
  },
  error: {
    status: 'error',
    text: 'danger',
  },
};

export const DEFAULT_STORAGE_SIZE = 3221225472;

export const DEFAULT_STORAGE_PLAN = {
  id: 'DEFAULT_STORAGE_PLAN_ID',
  isDefaultPlan: true,
  stripe_plan: { amount: 0, metadata: { storage_size: DEFAULT_STORAGE_SIZE } },
};

export const ADDONS_TYPES = {
  storage: 'storage',
};
export const settingsTabs = ['personal', 'billing-team', 'users', 'team-settings'];

export const changeStorageOriginMapping = {
  '/manage-storage': 'storage_management',
  '/billing-team': 'billing_page',
  '/meeting-notes': 'notification_banner',
};

export const meetingInteractionsTypeKeyMapping = {
  star: 'starred',
  read: 'read',
};

export const initialURLFilters = {
  dates: { value: '', type: 'date', optional: true },
  contains: { value: [], type: 'array', optional: true },
  owner: { value: [], type: 'array', optional: true },
  access: { value: '', type: 'string', optional: true },
  sort: { value: 'desc', type: 'string', optional: false },
  sortKey: { value: 'created_at', type: 'string', optional: false },
  page: { value: 1, type: 'number', optional: false },
  limit: { value: 50, type: 'number', optional: false },
  starred: { value: false, type: 'boolean', optional: true },
};

export const validPlanTransitions = {
  proMonthly: ['proAnnual', 'maBusinessMonthly'],
  proAnnual: ['maBusinessAnnual'],
  maBusinessMonthly: ['maBusinessAnnual'],
};

export const validPlanTransitionsPaypal = {
  proMonthly: ['proAnnual'],
};

export const discountEligibleSeatsCount = 50;

export const deletableCoupons = ['2-month-free'];
