/* eslint-disable spellcheck/spell-checker */
import { useEffect } from 'react';
import { GTagService } from 'services/GTagService';
import useScript from 'shared/hooks/useScript';

const InlineScripts = ({ children }) => {
  // // TrustBox script
  useScript({ src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' });
  useScript({ src: 'https://script.tapfiliate.com/tapfiliate.js' });
  useScript({
    src: 'https://www.google.com/recaptcha/api.js?render=6LfI5A4cAAAAAJK0YpOfADOVKOzZW2XB55MFmSWM&render=explicit',
  });
  useScript({
    src: 'https://static.zdassets.com/ekr/snippet.js?key=cccd5f74-66f6-43bc-9eb8-644df922cbb0',
    id: 'ze-snippet',
  });
  useScript({
    src: 'https://accounts.google.com/gsi/client',
    async: '',
    defer: '',
  });

  useScript({
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    async: '',
    defer: '',
    'data-language': 'en',
    'data-domain-script': 'cd7e9c5d-1e9f-4aa8-8be3-5a31b1494624',
  });

  const tapfiliateInit = () => {
    (function (t, a, p) {
      t.TapfiliateObject = a;
      t[a] =
        t[a] ||
        function () {
          (t[a].q = t[a].q || []).push(arguments);
        };
    })(window, 'tap');
    window.tap('create', '10671-17f705');
    window.tap('detect');
  };

  const zendeskInit = () => {
    window.zE && window.zE('webWidget', 'hide');
  };

  useEffect(() => {
    tapfiliateInit();
    zendeskInit();
    GTagService.init();
  }, []);

  return children;
};

export default InlineScripts;
