export const freeFeatures = [
  {
    text: 'note_feature_free_list_1',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'red', hasIcon: false },
  },
  {
    text: 'note_feature_free_list_2',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'red', hasIcon: false },
  },
  {
    text: 'note_feature_free_list_3',
    subText: 'note_60_minutes_per_day',
    withLozenge: true,
    lozenge: { type: 'red', hasIcon: false },
  },
  {
    text: 'note_feature_free_list_4',
    subText: 'note_2_per_day',
    withLozenge: true,
    lozenge: { type: 'red', hasIcon: false },
  },
  {
    text: 'note_feature_free_list_5',
    subText: 'note_n_days',
    withLozenge: true,
    lozenge: { type: 'red', hasIcon: false },
  },
];

export const proFeatures = [
  {
    text: 'note_feature_pro_list_1',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'blue', hasIcon: false },
  },
  {
    text: 'note_feature_pro_list_2',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'blue', hasIcon: false },
  },
  {
    text: 'note_feature_pro_list_3',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'purple', hasIcon: true },
  },
  {
    text: 'note_feature_pro_list_4',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'purple', hasIcon: true },
  },
  {
    text: 'note_feature_pro_list_5',
    subText: 'note_unlimited',
    withLozenge: false,
    lozenge: { type: 'blue', hasIcon: false },
  },
  {
    text: 'note_feature_pro_list_6',
    subText: 'note_extra_storage_addon',
    withLozenge: true,
    hideOnReverseTrial: true,
    lozenge: { type: 'blue', hasIcon: false },
  },
];

export const ma_business_features = [
  {
    text: 'note_feature_ma_business_list_1',
    subText: 'note_custom_storage_addon',
    withLozenge: true,
    lozenge: { type: 'blue', hasIcon: false },
  },
  {
    text: 'note_feature_ma_business_list_2',
  },
  {
    text: 'note_feature_ma_business_list_3',
  },
  {
    text: 'note_feature_ma_business_list_4',
  },
  {
    text: 'note_feature_ma_business_list_5',
    subText: 'note_soon',
    withLozenge: true,
    lozenge: { type: 'blue', hasIcon: false },
  },
  {
    text: 'note_feature_ma_business_list_6',
    subText: 'note_soon',
    withLozenge: true,
    lozenge: { type: 'blue', hasIcon: false },
  },
];

export const planSettings = {
  free: {
    title: 'note_your_plan',
    plan: 'title_free',
  },
  pro_month: {
    title: 'note_your_plan',
    plan: 'note_pro_monthly',
  },
  pro_year: {
    title: 'note_your_plan',
    plan: 'note_pro_yearly',
  },
  pro_trial: {
    title: 'note_your_plan_will_end',
    plan: 'note_pro_trial',
  },
  pro_team_trial: {
    title: 'note_your_plan_will_upgrade',
    plan: 'note_pro_trial',
  },
  pro_year_renew: {
    title: 'note_your_plan_will_renew',
    plan: 'note_pro_yearly',
  },
  pro_month_renew: {
    title: 'note_your_plan_will_renew',
    plan: 'note_pro_monthly',
  },
  pro_year_downgrade: {
    title: 'note_your_plan_will_downgrade',
    plan: 'note_pro_yearly',
  },
  pro_month_downgrade: {
    title: 'note_your_plan_will_downgrade',
    plan: 'note_pro_monthly',
  },
  ma_business_month: {
    title: 'note_your_plan',
    plan: 'note_ma_business_monthly', // 'note_pro_monthly',
  },
  ma_business_year: {
    title: 'note_your_plan',
    plan: 'note_ma_business_yearly', // 'note_pro_yearly',
  },
  ma_business_trial: {
    title: 'note_your_plan_will_end',
    plan: 'note_ma_business_trial', // 'note_pro_trial',
  },
  ma_business_team_trial: {
    title: 'note_your_plan_will_upgrade',
    plan: 'note_ma_business_trial', // 'note_pro_trial',
  },
  ma_business_year_renew: {
    title: 'note_your_plan_will_renew',
    plan: 'note_ma_business_yearly', // 'note_pro_yearly',
  },
  ma_business_month_renew: {
    title: 'note_your_plan_will_renew',
    plan: 'note_ma_business_monthly', // 'note_pro_monthly',
  },
  ma_business_year_downgrade: {
    title: 'note_your_plan_will_downgrade',
    plan: 'note_ma_business_yearly', // 'note_pro_yearly',
  },
  ma_business_month_downgrade: {
    title: 'note_your_plan_will_downgrade',
    plan: 'note_ma_business_monthly', // 'note_pro_monthly',
  },
};
