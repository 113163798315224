// Load Configs
import { EXTERNAL_LINKS } from './consts';

// Load Icons
import zapier from 'assets/images/integrations/zapier.svg';
import hubspot from 'assets/images/integrations/hubspot.svg';
import slack from 'assets/images/integrations/slack.svg';

export const INTEGRATIONS = {
  zapier: {
    name: 'Zapier',
    icon: zapier,
    learnMore: EXTERNAL_LINKS.integrations_learn_more,
    beta: false,
    permissions: [
      'View all owned and shared meeting related data',
      'View all action items with assignees', // eslint-disable-line spellcheck/spell-checker
      'View all transcripts',
      'View all notes',
    ],
  },
  hubspot: {
    name: 'HubSpot',
    icon: hubspot,
    survey: EXTERNAL_LINKS.integrations_upcoming_hubspot,
  },
  slack: {
    name: 'Slack',
    icon: slack,
    survey: EXTERNAL_LINKS.integrations_upcoming_slack,
    permissions: [
      'View all owned and shared meeting related data',
      'View all action items with assignees', // eslint-disable-line spellcheck/spell-checker
      'View all transcripts',
      'View all notes',
    ],
  },
};
