import React, { useRef, useState } from 'react';
import { default as TooltipWrapper } from '@material-ui/core/Tooltip';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { tooltipSettings } from 'configs/settings';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { isMobileOrTablet } from 'utils/appHelpers';

const themeVariants = {
  dark: {
    tooltip: {
      color: '#FFFFFF',
      backgroundColor: '#3C4151',
    },
    arrow: {
      color: '#3C4151',
    },
  },
  light: {
    tooltip: {
      color: '#3C4151',
      backgroundColor: '#FFFFFF',
      padding: '12px 20px',
    },
    arrow: {
      color: '#FFFFFF',
    },
  },
};

const Tooltip = (props) => {
  const target = useRef();
  const [isTooltipOpen, setTooltipOpen] = useState(props.open || false);
  const isMobileView = isMobileOrTablet();

  const { children, variant = 'light', styles, ...restProps } = props;
  const tooltipProps = {
    ...tooltipSettings,
    ...restProps,
    // onOpen, onClose works for desktop only
    onOpen: props.open ? props.onOpen : () => setTooltipOpen(true),
    onClose: () => setTooltipOpen(false),
    // open works for mobile & tablet devices only
    open: isTooltipOpen,
    onClick: (event) => {
      if (!isMobileView) return;
      event.stopPropagation();
      event.preventDefault();
      setTooltipOpen(true);
    },
  };

  const variantStyles = themeVariants[variant];

  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 500,
          padding: '14px 20px',
          maxWidth: '320px',
          borderRadius: '10px',
          color: '#FFFFFF',
          whiteSpace: 'pre-line',
          boxShadow: '2px 4px 15px rgba(171, 189, 207, 0.25)',
          fontFamily: '"Lexend", sans-serif',
          ...variantStyles.tooltip,
          ...styles?.tooltip,
        },
        arrow: {
          fontSize: 20,
          ...variantStyles.arrow,
          ...styles?.arrow,
          '&::before': {
            borderTopLeftRadius: '2px',
          },
        },
      },
    },
  });

  useOutsideClick(target, () => setTooltipOpen(false), isMobileView);

  return (
    <MuiThemeProvider theme={theme}>
      <TooltipWrapper {...tooltipProps}>
        <span ref={target}>{children}</span>
      </TooltipWrapper>
    </MuiThemeProvider>
  );
};

export default Tooltip;
