import React from 'react';

// Load Components
import Skeleton from '@material-ui/lab/Skeleton';

const AppLoading = ({ className }) => (
  <Skeleton animation='wave' variant='rect' className={`h-100-vh-i ${className}`} />
);

export default AppLoading;
