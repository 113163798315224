import { createReducer } from 'utils/reduxHelpers';
import { AUTH, INSIGHT } from 'redux/actionTypes';

const initialState = {
  dateIndex: 0,
};

const handlers = {
  [INSIGHT.SET_FILTER]: (state, action) => action.payload,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
