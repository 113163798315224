import React, { lazy, useEffect } from 'react';

// Load Vendors
import { Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';

// Load HOCs
import withUserActions from 'shared/HOCs/withUserActions';

// Load Hooks
import useRoutingListener from 'shared/hooks/useRoutingListener';
import useExternalError from 'shared/hooks/useExternalError';

// Load Components
import AppLoading from 'shared/components/loaders/AppLoading';

import { getENV } from 'configs/host';
import { StorageService } from 'services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import i18n from 'i18next';
import ResourceActions from 'redux/actions/resourceActions';
import { EXTERNAL_LINKS } from '../configs';
import { generateRid, getQueryParam } from 'utils/appHelpers';
import { SocketEvents, useSocket } from 'providers/SocketProvider';
import OAuth2AuthorizeCallback from './routes/Auth/routes/OAuth2AuthorizeCallback';

// Load Routes
const UI = lazy(() => import('./components/UI'));
const OAuth2Authorize = lazy(() => import('./routes/Auth/routes/OAuth2Authorize'));
const RecordView = lazy(() => import('./routes/Dashboard/routes/Recordings/routes/RecordView'));
const Auth = lazy(() => import('./routes/Auth'));
const Gateway = lazy(() => import('./routes/Gateway'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const ShareCallSummary = lazy(() => import('./routes/Dashboard/routes/ShareCallSummary'));
const MeetingNotesView = lazy(() => import('./routes/Dashboard/routes/MeetingNotesView'));
const AgendaView = lazy(() => import('./routes/Dashboard/routes/AgendaView'));
const MeetingTranscriptView = lazy(() => import('./routes/Dashboard/routes/MeetingTranscriptView'));
const MeetingRequestAccessView = lazy(
  () => import('./routes/Dashboard/routes/MeetingRequestAccessView'),
);

const env = getENV();

const App = ({ authState, initAuthState, handleStorageUpdates, handleRequestFailInterceptor }) => {
  const socket = useSocket();
  const resources = useSelector((state) => state.resources);
  const couponCode = getQueryParam('tr_coupon_code');
  const token = StorageService.get('token');

  if (couponCode) {
    StorageService.set('coupon_code', couponCode);
  }

  useRoutingListener();
  useExternalError();
  const dispatch = useDispatch();

  const getChatResources = async () => {
    try {
      const locale = StorageService.get('locale') || i18n.language;
      const { data } = await Api.get('/resource/chat', { params: { ajax: 1, locale } });
      dispatch(ResourceActions.setChat(data.data));
    } catch (err) {
      const initOpts = { name: 'any', url: EXTERNAL_LINKS.chat_resources };
      dispatch(ResourceActions.setChat(initOpts));
    }
  };

  useEffect(() => {
    StorageService.onStorageUpdate(handleStorageUpdates);
    initAuthState();
    if (!resources.chat) getChatResources();
    Api.interceptors.response.use((response) => response, handleRequestFailInterceptor);
  }, []);

  // connect to socket
  useEffect(() => {
    if (token && !socket.connected) {
      socket.connect(token);
      return () => socket.disconnect();
    }
  }, [token]);

  useEffect(() => {
    if (socket.connected) {
      socket.emitWithAckEvent(SocketEvents.SUBSCRIBE, {
        req_id: generateRid(),
        data: {
          events: [
            SocketEvents.SYNC_PROFILE,
            SocketEvents.SYNC_CUSTOM_VOCABULARY,
            SocketEvents.SYNC_ACTIVITY_EVENT,
            SocketEvents.SYNC_ACTIVITY_COUNTER,
            SocketEvents.SYNC_CALENDAR,
          ],
        },
      });
    }
  }, [socket.connected]);

  if (authState === null) return <AppLoading />;

  const authStateComponentMap = {
    0: Auth,
    1: Gateway,
    2: Dashboard,
  };

  const Component = authStateComponentMap[authState];

  return (
    <>
      <Switch>
        {env !== 'prod' && <Route path='/ui' component={UI} />}
        <Route path='/share/record/:id' component={RecordView} />
        <Route path='/call-summary/:code' component={ShareCallSummary} />
        {authState && <Route path='/oauth2/authorize' component={OAuth2Authorize} />}
        {authState && <Route path='/oauth2/authorize/:id' component={OAuth2AuthorizeCallback} />}
        {!authState && <Route path='/n/:id' component={MeetingNotesView} />}
        {!authState && <Route path='/t/:id' component={MeetingTranscriptView} />}
        <Route path='/meeting-access' component={MeetingRequestAccessView} />
        {!authState && <Route path='/a/:id' component={AgendaView} />}
        {!authState && <Route path='/agenda/:id' component={AgendaView} />}
        <Route path='/' component={Component} />
        <Redirect from='*' to='/' />
      </Switch>
    </>
  );
};

export default compose(withUserActions)(App);
