import React from 'react';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

// Load Vendors
import { Redirect, Route } from 'react-router-dom';
import store from 'redux/store';
import UserActions from 'redux/actions/userActions';
import { getPreferenceBooleanValue } from 'utils/preferencesHelper';
import { StorageService } from './StorageService';
import moment from 'moment';
import { getCalendarPermissions } from 'utils/calendarHelpers';

/* All Permissions List
  settings_page
  download_page
  questionnaire_screen,
  switch_domain_promotion_applied
  user_promotion_subscription_paused
  user_promotion_subscription_pause_finished
  user_appsumo
  user_app_used
  user_email_corporate
  recordings_list_page
  user_mode_freemium
  user_mode_free_pro
  user_mode_pro
  user_free
  user_vcube
  user_standard
  sign_on_method_sso
  team_access
  team_pro
  team_free
  team_trial
  team_admin
  team_appsumo
  reverse_trial_active
  reverse_trial_ended
  team_has_credit_card
  team_has_subscription
  team_users_list_page
  team_device_list_page
  team_multiple_members
  team_type_standard
  team_type_device
  team_type_vcube
  team_subscription_invoice
  team_subscription_paypal
  team_subscription_card
  team_pricing_type_usage
  team_pricing_type_recurring
  skip_installation_screen
  open_app_button_access
  could_have_trial
  analytics_access
  meeting_notes_access
  meeting_notes_generation
  calendar_events
  calendar_contacts
  calendar_directory
  calendar_read
  calendar
  app_debug
  mfa_deactivation_access
  mfa_activation_access
  join_pro_team
  campaign_resurrection_promotion
*/

const preDefinedPermissions = {
  public_for_all_types: ['personal_account_page', 'download_page', 'team_audio_devices'],
};

// TEMPORARY until policies will be available on the backend part
const getDefinedPermissions = (profile, settingsTree) => {
  const isFirstTimeLogin = StorageService.get('is_first_time_login');
  const excludeTypeForm = StorageService.get('exclude_typeform');
  const typeformHasShown = StorageService.get('type_form');
  const result = [];
  const {
    mode,
    pause_two_months_exp,
    team,
    is_corporate,
    switch_domain_promotion_applied,
    country,
    oauth_scopes: oas,
    to_yearly_applied,
    next_year_discount_applied,
    promotion_eligibility,
  } = profile;
  // User Permissions
  if (
    !is_corporate &&
    !switch_domain_promotion_applied &&
    mode?.name !== 'trial' &&
    mode?.template?.corporate_bonus > 1
  ) {
    result.push('switch_to_corporate_domain');
  }

  if (isFirstTimeLogin && !excludeTypeForm && !typeformHasShown) {
    result.push('questionnaire_screen');
  }

  if (pause_two_months_exp && new Date() < new Date(pause_two_months_exp))
    result.push('user_promotion_subscription_paused');
  if (
    (pause_two_months_exp && new Date() > new Date(pause_two_months_exp)) ||
    country === 'Philippines'
  ) {
    result.push('user_promotion_subscription_pause_finished');
  }

  if (country === 'Philippines') {
    result.push('has_local_promotion');
  }

  if (promotion_eligibility?.some((prom) => prom.key === 'campaign_resurrection')) {
    result.push('campaign_resurrection_promotion');
  }

  if (to_yearly_applied) {
    result.push('user_promotion_to_yearly');
  }

  if (next_year_discount_applied) {
    result.push('user_promotion_next_year');
  }

  if (profile.is_appsumo) result.push('user_appsumo');
  if (profile.has_source_next) result.push('source_next');
  if (profile.is_corporate) result.push('user_email_corporate');

  if (mode.name === 'minutes') result.push('user_mode_freemium');
  if (mode.name === 'trial') result.push('user_mode_free_pro');
  if (mode.name === 'unlimited') result.push('user_mode_pro');

  if (profile.plan === 'Free') result.push('user_free');

  if (profile.type === 'vcube') result.push('user_vcube');
  if (profile.type === 'standard') result.push('user_standard');

  if (profile.sign_on_method === 'saml') result.push('sign_on_method_sso');

  if (profile.meeting_notes_balance?.balance > 0) result.push('meeting_notes_generation');

  if (profile.meeting_notes_balance?.mode === 'free') result.push('meeting_notes_mode_free');
  if (profile.meeting_notes_balance?.mode === 'trial') result.push('meeting_notes_mode_trial');
  if (profile.meeting_notes_balance?.mode === 'unlimited')
    result.push('meeting_notes_mode_unlimited');

  const calendarPermissions = getCalendarPermissions(oas);
  if (calendarPermissions.length > 0) result.push(...calendarPermissions);

  if (profile.mfa_enabled) result.push('mfa_deactivation_access');
  if (!profile.mfa_enabled) result.push('mfa_activation_access');

  if (!team) return result;

  if (
    profile.has_used_app ||
    StorageService.get('hasAttemptedAppSignin') ||
    StorageService.get('request_meeting_access_data') ||
    (StorageService.get('skip_download') && team.seat.role === 'Admin')
  ) {
    result.push('user_app_used');
  }

  // Team Permissions
  if (team.plan === 'Pro') {
    result.push('team_pro');
    const { seat } = team;
    if (seat) {
      const { type = null, trial_expiration_date = null } = seat;
      if (type && type === 'trial') {
        const remainingDuration = moment(trial_expiration_date).diff(moment(), 'seconds');
        if (remainingDuration > 0) {
          result.push('seat_trial_active');
        }
      }
    }
  }

  if (team.plan === 'Ma_business') result.push('plan_ma_business');
  if (team.plan === 'Free') {
    result.push('team_free');
    const secondsLeft = mode?.balance || 0;

    if (profile.reverse_trial_applied === 1 && secondsLeft <= 0) {
      result.push('reverse_trial_ended');
    }
    if (profile.reverse_trial_active && secondsLeft > 0) result.push('reverse_trial_active');
  }

  if (team.plan === 'Business') result.push('plan_business');
  if (team.plan === 'Enterprise') result.push('plan_enterprise');
  if (team.trial) result.push('team_trial');
  if (team.seat.role === 'Admin') {
    result.push('team_admin');
    if (team.plan !== 'Free') result.push('settings_page');
    if (team.seats.count > 1) result.push('skip_installation_screen');
    if ((team.plan === 'Business' || team.plan === 'Enterprise') && team.type !== 'device') {
      result.push('domain_management_access');
    }
    if (team.has_verified_domain) result.push('domain_verified');
    if (
      (team.plan === 'Business' || team.plan === 'Enterprise') &&
      team.seats.pending_request_count > 0
    ) {
      result.push('has_wait_list_access');
    }
  }

  if (team.is_appsumo) result.push('team_appsumo');

  if (team.members_count > 1) result.push('team_multiple_members');
  if (team.seats.count > 1) result.push('team_multiple_seats');
  if (team.has_credit_card) result.push('team_has_credit_card');
  if (team.has_subscription) result.push('team_has_subscription');
  if (team.enterprise_version_exists) result.push('enterprise_version_exists');

  if (team.type === 'standard') result.push('team_type_standard', 'team_users_list_page');
  if (team.type === 'device') result.push('team_type_device', 'team_device_list_page');
  if (team.type !== 'device') result.push('team_type_non_device', 'insights_page');
  if (team.type === 'vcube') result.push('team_type_vcube', 'team_users_list_page');

  if (team.payment_type === 'invoice') result.push('team_subscription_invoice');
  if (team.payment_type === 'paypal') result.push('team_subscription_paypal');
  if (team.plan === 'Free' && country !== 'United States') result.push('has_paypal_access');
  if (team.payment_type === 'card') result.push('team_subscription_card');

  if (team.pricing === 'usage') result.push('team_pricing_type_usage');
  if (team.pricing === 'recurring') result.push('team_pricing_type_recurring');

  const {
    sso,
    public_api,
    release_channel,
    session_duration,
    open_app_trigger_button,
    send_system_user_data,
    recording_features,
    asr_features,
    analytics,
    app_debug,
    widget,
    integrations,
  } = settingsTree;

  if (release_channel?.available.value && release_channel?.state?.value === 'enterprise')
    result.push('enterprise_setting_is_active');

  if (release_channel?.available.value) result.push('enterprise_setting_is_available');

  if (release_channel?.enforce_enterprise?.value) result.push('enforce_enterprise_enabled');

  if (sso && getPreferenceBooleanValue(sso.available)) {
    result.push('team_sso_access');
  }

  if (public_api && getPreferenceBooleanValue(public_api.available)) {
    result.push('team_public_api_access');
  }
  if (
    profile.sign_on_method !== 'saml' &&
    profile.is_recording_approved &&
    profile.recording &&
    profile.recording.is_old_recording_user
  ) {
    result.push('recordings_list_page');
  }

  if (session_duration && getPreferenceBooleanValue(session_duration.available)) {
    result.push('team_session_duration_access');
  }

  if (send_system_user_data) {
    result.push('team_send_system_user_data_access');
  }

  if (open_app_trigger_button && getPreferenceBooleanValue(open_app_trigger_button.available)) {
    result.push('open_app_button_access');
  }

  if (StorageService.get('direct_pro_checkout') && !result.includes('user_app_used')) {
    result.push('direct_pro_checkout');
  }

  if (team.could_have_trial) {
    result.push('could_have_trial');
  }

  if (getPreferenceBooleanValue(recording_features?.meeting_recording?.available)) {
    result.push('recording_feature_access');
  }

  if (getPreferenceBooleanValue(asr_features?.call_transcript?.meeting_notes?.available)) {
    result.push('meeting_notes_access');
  }

  if (team.seat.role === 'Admin' && getPreferenceBooleanValue(analytics.available)) {
    result.push('analytics_access');
  }

  if (getPreferenceBooleanValue(app_debug.available)) {
    result.push('app_debug');
  }

  if (getPreferenceBooleanValue(widget.available)) {
    result.push('widget');
  }

  if (
    getPreferenceBooleanValue(asr_features?.call_transcript?.meeting_notes?.auto_summary?.available)
  ) {
    result.push('auto_summary_access');
  }

  if (getPreferenceBooleanValue(integrations?.available)) {
    result.push('integrations_page');
  }

  if (profile.policies) {
    const { policies } = profile;
    if (policies.meeting_bot) result.push('meeting_bot');
  }

  return result;
};

export const PermissionService = {
  setUserPermissions: (profile, settingsTree) => {
    const userPermissions = getDefinedPermissions(profile, settingsTree);
    const allPermissions = [...userPermissions, ...preDefinedPermissions.public_for_all_types];
    store.dispatch(UserActions.setPermissions(allPermissions));
  },
  cleanUserPermissions: () => {
    store.dispatch(UserActions.setPermissions([]));
  },
  hasAccess: (name) => {
    const { permissions } = store.getState();
    return !!permissions.includes(name);
  },
};

const { hasAccess } = PermissionService;

export { hasAccess };

export const PRoute = ({ redirectUrl = '/', ...restProps }) => {
  const { access, applyWith = every } = restProps;
  let accessible = false;
  if (typeof access === 'string') {
    accessible = hasAccess(access);
  } else if (isArray(access)) {
    accessible = applyWith(access, (permission) => hasAccess(permission));
  } else if (isFunction(access)) {
    accessible = access();
  } else if (!access) {
    accessible = true;
  }
  if (accessible) {
    return <Route {...restProps} />;
  } else {
    return <Redirect to={redirectUrl} />;
  }
};
