import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';

const initialState = 0;

const handlers = {
  [USER.SET_ANALYTICS_DASHBOARD_TAB_INDEX]: (state, action) => action.payload,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
