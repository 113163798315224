import React, { memo, useEffect } from 'react';
import isArray from 'lodash/isArray';
import * as Sentry from '@sentry/react';

// Load Apis
import { Api, setAuthToken, isAxiosError } from 'utils/connectors';

// Load Actions
import AuthActions from 'redux/actions/authActions';
import UserActions from 'redux/actions/userActions';

// Load Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Load Services
import { AnalyticsService } from 'services/AnalyticsService';
import { HotjarService } from 'services/HotjarService';
import { PermissionService } from 'services/PermissionService';
import { MeetingAccessService } from 'services/MeetingAccessService';
import { StorageService } from 'services/StorageService';
import { CookieService } from 'services/CookieService';

// Load Vendors
import { setZenDeskChatData } from 'utils/zenDeskHelpers';
import { tapfiliateTrack } from 'utils/tapfiliateHelpers';
import { localUrlRedirections, reg, IS_LOCAL } from 'configs';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { extractUUIDFromString, getQueryParam, isMobileOrTablet } from 'utils/appHelpers';
import { getSignupSource } from 'utils/meetingNotesHelpers';
import { SocketEvents, useSocket } from 'providers/SocketProvider';
import { QuoraService } from 'services/QuoraService';

const { hasAccess } = PermissionService;

const withUserActions = (Component) =>
  memo((props) => {
    const socket = useSocket();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    const token = StorageService.get('token');

    const clearForLogout = (clearStorageTypes = [], redirectUrl = '/') => {
      dispatch(AuthActions.setState(0));
      history.push(redirectUrl);
      dispatch(AuthActions.logout());
      setAuthToken('');
      AnalyticsService.cleanUserEvents();
      StorageService.removeTypes([
        ...(isArray(clearStorageTypes) ? clearStorageTypes : []),
        'authFlow',
      ]);
      StorageService.remove('is_team_create_flow');
      StorageService.remove('direct_pro_checkout');
      StorageService.remove('exclude_typeform');
      StorageService.remove('from_plan');
      StorageService.remove('from_interval');
      StorageService.remove('do_not_show_calls_less_5_minute');
      CookieService.remove('krisp_account_type');
      StorageService.remove('is_first_time_login');
      MeetingAccessService.clearCache();
    };

    const changeUserTeam = async (team_id) => {
      try {
        if (team_id) {
          await Api.patch('auth/token/web', { team_id });
        }
        await getAndSetUserData();
        if (StorageService.get('app_jwt')) {
          StorageService.set('app_account', true);
        }
      } catch (err) {
        Sentry.captureException(err);
        clearForLogout();
      }
    };

    useEffect(() => {
      if (socket.connected) {
        const unsubscribe = socket.subscribe(SocketEvents.SUBSCRIPTION_MESSAGE, async (data) => {
          if (
            data.event === SocketEvents.SYNC_PROFILE &&
            !location.pathname.includes('/billing-team/upgrade')
          ) {
            await getAndSetUserData();
          }
        });
        return () => unsubscribe();
      }
    }, [socket.connected, location.pathname]);

    const getProfile = async () => {
      try {
        const { data } = await Api.get('/user/profile/2');
        const { preferences, ...rest } = data.data;
        PermissionService.setUserPermissions(data.data, preferences.settings);
        AnalyticsService.pushUser(data.data);
        HotjarService.setUser(data.data.id);
        setZenDeskChatData(data.data);
        return [rest, preferences];
      } catch (err) {
        Sentry.captureException(err);
        throw err;
      }
    };

    const getAndSetUserTeams = async () => {
      try {
        const { data } = await Api.get('/user/accounts');
        dispatch(UserActions.setAccounts(data.data));
        return data.data;
      } catch (err) {
        // handle error
        console.error(err);
      }
    };

    const getAndSetUserAvailableTeams = async () => {
      try {
        const { data } = await Api.get('team/workspace/available-list');
        dispatch(UserActions.setAvailableTeams(data.data));
        return data.data;
      } catch (err) {
        // handle error
        console.error(err);
      }
    };

    const getAndSetUserData = async () => {
      try {
        const [data, preferences] = await getProfile();
        setUserData(data);
        setProfilePreferences(preferences);
        return data;
      } catch (err) {
        throw err;
      }
    };

    const setUserData = (data) => dispatch(AuthActions.login(data));
    const setProfilePreferences = (data) =>
      dispatch(UserActions.setPreferences({ branch: 'profile', preferences: data }));

    const loginUser = async ({ data }, metaData) => {
      if (data.mfa_required) {
        history.push(`/mfa/${data.code}`, { method: metaData.method });
        return;
      }
      const { method } = metaData;
      const { token, web_first_time_login: isFirstTimeLogin, is_first_time_app_login } = data;
      const hasAppJWT = StorageService.get('app_jwt');
      const from = hasAppJWT ? 'desktop' : 'web';
      const signUpEventData = {
        sign_up_method: method,
        is_work_domain: false,
      };

      const signInEventData = {
        from,
        sign_in_method: method,
        is_work_domain: false,
        is_first_time_desktop: !!is_first_time_app_login,
      };

      if (StorageService.get('campaign_resurrection')) {
        signInEventData.campaign_resurrection = true;
      }

      try {
        // If is first time login keep in store
        if (isFirstTimeLogin) StorageService.set('is_first_time_login', true);

        StorageService.set('token', token);
        const user = await handleSigninWithToken(token);
        if (!user) return;
        if (isFirstTimeLogin) {
          await tapfiliateTrack(user.id);
          signUpEventData.is_work_domain = user?.is_corporate;
          signUpEventData.flow = getSignupSource();
          AnalyticsService.sendEvent(AnalyticsService.events.fn_sign_up, {
            data: signUpEventData,
            status: 'success',
          });
          AnalyticsService.pushToDataLayer({
            event: 'conversion',
            send_to: 'AW-781170108/22QCCMTP7KsYELzrvvQC',
          });
          QuoraService.trackEvent('CompleteRegistration');
        } else {
          signInEventData.is_work_domain = user?.is_corporate;
          AnalyticsService.sendEvent(AnalyticsService.events.fn_sign_in, {
            data: signInEventData,
            status: 'success',
          });
        }
      } catch (err) {
        console.error(err);
        if (isFirstTimeLogin) {
          signUpEventData.flow = getSignupSource();
          AnalyticsService.trackFailure(AnalyticsService.events.fn_sign_up, err, signUpEventData);
        } else {
          AnalyticsService.trackFailure(AnalyticsService.events.fn_sign_in, err, signUpEventData);
        }
        clearForLogout();
      }
    };

    const logout = async () => {
      try {
        Api.get('auth/logout');
      } catch (err) {
        clearForLogout(['appAuthFlow']);
      } finally {
        clearForLogout(['appAuthFlow']);
      }
    };

    const handleRequestFailInterceptor = (error) => {
      if (error.response && error.response.status) {
        const { status, error_code, config } = error.response;
        const isLogoutAction = config.url === 'auth/logout';
        const isInvalidToken = status === 400 && error_code === 'AUTH_BEARER_INVALID_TOKEN';
        if ((status === 401 || isInvalidToken) && !isLogoutAction) {
          Sentry.captureException(error);
          logout();
        }
      }
      return Promise.reject(error);
    };

    const handleForLogout = (redirectUrl) => {
      dispatch(AuthActions.setState(0));
      clearForLogout(null, redirectUrl);
    };

    const handleSigninWithToken = async (newToken = token, ignoreGateway = false) => {
      try {
        setAuthToken(newToken);
        let profile = await getAndSetUserData();
        const accountsRequest = getAndSetUserTeams();
        const hasAppJWT = getQueryParam('app') || StorageService.get('app_jwt');
        const appAccountSelected = StorageService.get('app_account');
        const requestMeetingAccessGrant = StorageService.get('request_meeting_access_data');
        const getAccountState = async () => {
          // Wait for accounts response
          const { data: accounts } = await accountsRequest;

          // User needs to request meeting access, in this case the user must directly navigate to "Request access" page
          if (requestMeetingAccessGrant) {
            // If user doesn't have team, and it's the only team, then attach team to user and redirect to dashboard
            if (accounts.length === 1 && !profile.team) {
              await Api.patch('auth/token/web', { team_id: accounts[0]?.team_id });
              await getAndSetUserData();
            }
            return 2;
          }

          if (accounts.length === 0) {
            const availableTeams = await getAndSetUserAvailableTeams();

            // If user logged in with workspace domain and didn't join to any team
            if (availableTeams?.teams?.length > 0 && !profile.team) return 1;
          }

          // If user already has team, and it's not app authorization flow then redirect to dashboard
          if (profile.team && (!hasAppJWT || appAccountSelected)) return 2;

          // Logout user if there are no accounts
          if (!accounts.length) {
            return 0;
          }

          // If user already has team, and it's the only team, then redirect to dashboard
          if (accounts.length === 1 && profile.team) return 2;

          // If user doesn't have team, and it's the only team, then attach team to user and redirect to
          // 1. calendar connect if user doesn't have calendar access
          // 2. dashboard if user has calendar access
          if (accounts.length === 1 && !profile.team) {
            await Api.patch('auth/token/web', { team_id: accounts[0]?.team_id });
            profile = await getAndSetUserData();
            if (!hasAccess('calendar') && !StorageService.get('skip_calendar_access_v2')) {
              await getAndSetUserAvailableTeams();
              return 1;
            }
            return 2;
          }

          // If user joined to a team and didn't connect calendar
          if (
            profile.team &&
            !hasAccess('calendar') &&
            !StorageService.get('skip_calendar_access_v2')
          ) {
            await getAndSetUserAvailableTeams();
            return 1;
          }

          // There is an app authorization flow, but the team is enforced
          if (ignoreGateway && !!profile.team) return 2;

          // Redirect user to team select page
          return 1;
        };

        const accountState = await getAccountState();

        dispatch(AuthActions.setState(accountState));

        if (!accountState) {
          throw new Error('No account found');
        }
        return profile;
      } catch (err) {
        if (IS_LOCAL && isAxiosError(err) && err?.response?.status !== 401) {
          throw err;
        }

        handleForLogout();
      }
    };

    const handleSSOUrl = async (sso, ignoreGateway, isVerified) => {
      try {
        let path = `/auth/login/sso/${sso}`;
        if (isVerified) {
          path = `/auth/login/sso/verify/${sso}`;
        }
        const { data } = await Api.get(path);
        if (data.data.token) {
          StorageService.set('token', data.data.token);
          handleSigninWithToken(data.data.token, ignoreGateway);
        } else if (data.data.grant) {
          const { email, grant } = data.data;
          window.location.replace(
            `${window.location.origin}/sso/verify?email=${encodeURIComponent(
              email,
            )}&grant=${grant}`,
          );
        }
      } catch (err) {
        handleForLogout();
      }
    };

    const handleAPPJWTUrl = async (appJWT, isAppAuthorizationFlow) => {
      if (isMobileOrTablet()) return history.replace('/meeting_notes');
      StorageService.set('app_jwt', appJWT);
      if (isAppAuthorizationFlow) StorageService.set('is_app_authorization_flow', true);
    };

    const handleLocaleUrl = () => {
      const storeLocale = StorageService.get('locale');
      const locale = getQueryParam('locale');
      if (locale && !storeLocale) StorageService.set('locale', locale);
    };

    const handleDirectCheckoutFlow = () => {
      const utm_medium = getQueryParam('utm_medium');
      const utm_source = getQueryParam('utm_source');
      const { pathname } = location;
      if (
        pathname === '/billing-team/upgrade-to-pro' &&
        (utm_source === 'website' || utm_source === 'email') &&
        utm_medium === 'pro_flow'
      ) {
        StorageService.set('direct_pro_checkout', true);
      }
    };

    const handleGrantUrl = () => {
      StorageService.remove('email_grant');
      const grant = getQueryParam('grant');
      if (grant) StorageService.set('email_grant', grant);
    };

    const handleGrantLogin = async (loginGrant, ignoreGateway) => {
      try {
        const { pathname } = location;
        const { data } = await Api.post('auth/login/grant', { grant: loginGrant });
        StorageService.set('token', data.data.token);
        await handleSigninWithToken(data.data.token, ignoreGateway);
        if (StorageService.get('app_jwt')) {
        } else {
          history.replace(pathname);
        }
      } catch {
        enqueueSnackbar('Sorry, this URL has expired', { variant: 'error' });
        handleForLogout();
      }
    };

    const handleTeamJoin = () => {
      const isTeamJoinAttempt = getQueryParam('action') === 'rejoin';
      if (isTeamJoinAttempt) {
        StorageService.remove('skipTeamJoinPage');
      }
    };

    const handleInviteMoreUsersFlow = () => {
      const { pathname } = location;
      const flow = getQueryParam('tr_origin');
      if (
        pathname === '/users/list/invite' &&
        ['successful_join_email', 'welcome_email'].includes(flow)
      ) {
        StorageService.set('invite_teammate_flow', flow);
      }
    };

    const handleAuthState = async () => {
      const hasSSO = getQueryParam('sso');
      const isVerified = getQueryParam('verify');
      const isLoginGrant = getQueryParam('login-grant');
      const isInviteLink =
        location.pathname.startsWith('/invite') ||
        (location.pathname.startsWith('/sign-up') && !!getQueryParam('grant'));
      const hasAppJWT = getQueryParam('app') || StorageService.get('app_jwt');
      const wasPeviouslyRegistered = StorageService.get('was_previously_registered') === 'true';
      const isSSOFlow = location.pathname.includes('/sso/');
      const isAppAuthorizationFlow =
        hasAppJWT &&
        (location.pathname === '/login/1' ||
          !!StorageService.get('is_app_authorization_flow') ||
          getQueryParam('is_app_auth_flow'));
      const isFromCampaignResurrection = getQueryParam('campaign') === 'resurrection';

      if (isFromCampaignResurrection) {
        StorageService.set('campaign_resurrection', true);
      }

      // Save user locale
      handleLocaleUrl();

      // If user comes with grant url
      handleGrantUrl();

      // If user tried to join another team after receiving rejection email
      handleTeamJoin();

      // Save user flow for teammate invite
      handleInviteMoreUsersFlow();

      if (isLoginGrant) {
        await handleAPPJWTUrl(hasAppJWT, isAppAuthorizationFlow);
        handleGrantLogin(isLoginGrant, true);
        return;
      }

      // If User come with team invite link url and logged in,
      // redirect to dashboard and accept the invite
      // If User come with team invite link url and not logged in,
      // redirect to login page and after login, accept the invite
      if (token && isInviteLink) {
        handleSigninWithToken();
        history.push(`/meeting-notes${location.search}`);
        return;
      } else if (isInviteLink && !token && wasPeviouslyRegistered) {
        dispatch(AuthActions.setState(0));
        history.push(`/login${location.search}`);
        return;
      }

      // If user come with TalkDesk with singlesignon(sso)
      if (hasSSO) {
        handleSSOUrl(hasSSO, !hasAppJWT, isVerified);
        return;
      }

      // If has app JWT signin token
      if (hasAppJWT) {
        handleAPPJWTUrl(hasAppJWT, isAppAuthorizationFlow || isSSOFlow);
        if (!token) {
          dispatch(AuthActions.setState(0));
          return;
        }
      }

      if (token) {
        handleSigninWithToken();
        return;
      }

      if (location.pathname === '/oauth2/authorize') {
        StorageService.set('oauth_redirect_path', location.pathname + location.search);
      }

      if (!/login|sign-up|verify|invite/.test(location.pathname)) {
        StorageService.set('redirect_path', location.pathname);
      }

      handleDirectCheckoutFlow();
      dispatch(AuthActions.setState(0));
      AnalyticsService.pushUser(null);
    };

    const handleRedirection = (key, search = '') => {
      let redirection = localUrlRedirections[key];
      if (/ref\/u/.test(key)) redirection = localUrlRedirections['/ref/u'];
      if (/login\/sso/.test(key)) redirection = localUrlRedirections['/login/sso'];
      if (/^\/meeting-notes\//.test(key)) {
        const transcriptPageRequest = new RegExp(`/meeting-notes/${reg.uuidV4Reg.source}`, 'i');
        const notesPageRequest = new RegExp(`/meeting-notes/${reg.uuidV4Reg.source}/notes`, 'i');
        const id = key.split('/').find(extractUUIDFromString);
        if (transcriptPageRequest.test(key) && id) redirection = { to: `/t/${id}` };
        if (notesPageRequest.test(key) && id) {
          redirection = { to: `/n/${id}` };
          const sp = new URLSearchParams(search);
          const generate = sp.get('generate');
          const autoGenerateSp = sp.get('auto_generate');
          // Covering the old app versions flow
          if (!generate && !autoGenerateSp) {
            sp.set('auto_generate', 'false');
            search = `?${sp.toString()}`;
          }
        }
      }
      if (redirection) history.push(redirection.to + search, redirection.params);
    };

    const handleStorageUpdates = ({ type, value }) => {
      const actions = {
        logout: clearForLogout,
        login: handleSigninWithToken,
      };
      actions[type] && actions[type](value);
    };

    const initAuthState = async () => {
      await handleAuthState();
      const { pathname, search } = location;
      handleRedirection(pathname, search);
    };

    const newProps = {
      getAndSetUserData,
      getAndSetUserTeams,
      getAndSetUserAvailableTeams,
      setUserData,
      clearForLogout,
      loginUser,
      logout,
      onAuthState: AuthActions.setState(),
      changeUserTeam,
      authState: isAuthenticated,
      initAuthState,
      handleStorageUpdates,
      handleRequestFailInterceptor,
      ...props,
    };

    return <Component {...newProps} />;
  });

export default withUserActions;
