import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';

const initialState = null;

const handlers = {
  [USER.SET_AUDIO_DEVICES]: (state, action) => action.payload,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
