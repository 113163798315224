import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Load Apis
import { PublicApi, isAxiosError } from 'utils/connectors';

// Load Components
import AppLoading from 'shared/components/loaders/AppLoading';

// Load HOCs
import withUserActions from 'shared/HOCs/withUserActions';
import withDefaultProps from 'shared/HOCs/withDefaultProps';
import withQueryParams from 'shared/HOCs/withQueryParams';

// Load Vendors
import { compose } from 'redux';
import { INTEGRATIONS } from 'configs';

// Load Icons
import {
  Button,
  ButtonGroup,
  LightningFill,
  Logomark,
  PointerLeft,
  PointerRight,
} from '@krispai/kr-ui-components';

const OAuth2AuthorizeCallback = ({ t, authState, location }) => {
  const { push } = useHistory();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const account = useSelector((state) => state.account);
  const urlParams = new URLSearchParams(location.search);

  const oauthParams = {
    client_id: location.query.id,
    grant_type: 'authorization_code',
    redirect_uri: '/integrations',
    code: urlParams.get('code') || '',
    state: urlParams.get('state') || '',
  };

  const integrationData = {
    ...INTEGRATIONS?.[oauthParams.client_id],
    ...data,
  };


  useEffect(() => {
    authorize(oauthParams)
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          setError({
            code: error?.response?.data.error || 'server_error',
            message:
              error?.response?.data.error_description ||
              'Error during authorization process. Please try again later.',
          });
        }
        setLoading(false);
      });
  }, []);

  const onAllowClick = async () => {
    await authorize();
  };

  const onDenyClick = async () => {
    const redirectUri = new URL(integrationData.redirect_uri);
    redirectUri.searchParams.append('state', oauthParams.state);
    redirectUri.searchParams.append('error', 'access_denied');
    window.location.href = redirectUri.toString();
  };

  const authorize = async () => {
    const { status, data: authorizedData } = await PublicApi.post('/oauth2/authorize', oauthParams);

    if (status !== 200 || !authorizedData?.redirect_uri) {
      setError(
        authorizedData.message || 'Error during authorization process. Please try again later.',
      );
      return;
    }

    window.location.href = authorizedData.redirect_uri;
  };

  const goToUpgrade = () => {
    push('/billing-team/upgrade-to-pro');
  };

  if (loading || authState === null) return <AppLoading />;

  return (
    <div className='mxw-500-px m-auto text-v2-neutral-darker'>
      <div className='d-flex align-items-center justify-content-center pt-40 pb-20 border-bottom border-v2-neutral-lighter'>
        <div className='p-5'>
          <img src={integrationData.icon} width={60} height={60} alt={integrationData.name} />
        </div>
        <PointerLeft width={24} height={24} className='mr-1 ml-12 icon-v2-neutral-lighter' />
        <PointerRight width={24} height={24} className='ml-1 mr-12 icon-v2-neutral-lighter' />
        <div className='p-5'>
          <Logomark className='w-60-px h-60-px' />
        </div>
      </div>
      {(error?.code && (
        <div className='mt-20'>
          {(error?.code === 'access_denied' && (
            <>
              <p className='text-v2-xl fw-v2-medium text-center'>
                Krisp integration with {integrationData.name} is only available on our Pro and
                Business plans. To connect {integrationData.name}, upgrade your plan.
              </p>
              <Button
                className='w-100'
                size='md'
                appearance='primary'
                onClick={goToUpgrade}
                Icon={LightningFill}
                iconPosition='left'
              >
                {t('btn_upgrade')}
              </Button>
            </>
          )) || (
            <>
              <p className='s1-v2 fw-v2-bold'>Authorization Failure</p>
              <p className='text-v2-xl fw-v2-medium'>
                {error.code}: {error.message}
              </p>
            </>
          )}
        </div>
      )) || (
        <>
          <div className='mt-20'>
            <p className='s1-v2 fw-v2-bold'>
              {integrationData.name} is requesting permission to access your {account.email} account
            </p>
            <p className='text-v2-xl fw-v2-medium'>
              Give {integrationData.name} permission to <b>view</b> data in this workspace on your
              behalf.
            </p>
          </div>
          <div className='mt-20'>
            <p className='s1-v2 fw-v2-bold'>What will {data.name} be able to view?</p>
            <ul className='ml-12'>
              {integrationData?.permissions?.map((permission, index) => (
                <li key={index} className='text-v2-xl fw-v2-medium'>
                  {permission}
                </li>
              ))}
            </ul>
          </div>
          <ButtonGroup className='mt-20'>
            <Button
              onClick={onDenyClick}
              className='mr-2'
              appearance='secondary'
              size='lg'
              type='outline'
              block
            >
              Cancel
            </Button>
            <Button
              onClick={onAllowClick}
              className='ml-2'
              appearance='secondary'
              size='lg'
              type='fill'
              block
            >
              Allow
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
};

export default compose(withUserActions, withDefaultProps, withQueryParams)(OAuth2AuthorizeCallback);
