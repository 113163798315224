import React, { memo } from 'react';

// Load Hooks
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

// Load Vendors
import { getError } from 'utils/appHelpers';

// Load Services
import { PermissionService } from 'services/PermissionService';

const withDefaultProps = (Component) =>
  memo((props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { hasAccess } = PermissionService;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const alertError = (err, withoutCode = false) =>
      enqueueSnackbar(getError(err, withoutCode), { variant: 'error' });
    const alertSuccess = (msg) => enqueueSnackbar(msg, { variant: 'success' });

    const newProps = {
      t,
      Trans,
      dispatch,
      useSelector,
      alertError,
      alertSuccess,
      alert: enqueueSnackbar,
      hasAccess,
      ...props,
    };

    return <Component {...newProps} />;
  });

export default withDefaultProps;
