import { createReducer } from 'utils/reduxHelpers';
import { AUTH, MEETING } from 'redux/actionTypes';
import { cloneDeep, update } from 'lodash';

const initialState = null;

const handlers = {
  [MEETING.SET_MEETINGS]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [MEETING.SET_HISTORY_LOCKED_COUNT]: (state, action) => {
    return { ...state, historyLockedCount: action.payload };
  },
  [MEETING.TOGGLE_REMOVED_MEETING_IDS]: (state, action) => {
    let currentRemovedIDs = [...(state?.removedIds || [])];
    if (action.payload.bool) {
      currentRemovedIDs = [...currentRemovedIDs, ...action.payload.ids];
    } else {
      currentRemovedIDs = currentRemovedIDs.filter((id) => !action.payload.ids.includes(id));
    }
    return { ...state, removedIds: currentRemovedIDs };
  },
  [MEETING.CHANGE_MEETING_INTERACTION]: (state, action) => {
    const { value, id, key } = action.payload;
    const rowsClone = cloneDeep(state.rows);
    const meeting = rowsClone.find((row) => row.id === id);
    update(meeting, `user_interactions`, (old) => ({
      ...old,
      [key]: value,
    }));
    return { ...state, rows: rowsClone };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
