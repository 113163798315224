import React from 'react';

// Load Hooks
import { useLocation } from 'react-router-dom';

// Load Vendors
import { getPageTitle } from 'utils/appHelpers';

// Load Components
import { Helmet } from 'react-helmet';

const RouteTitle = ({ children }) => {
  const location = useLocation();
  const title = getPageTitle(location.pathname);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default RouteTitle;
