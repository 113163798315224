import { createReducer } from 'utils/reduxHelpers';
import { EXPERIMENT, AUTH } from 'redux/actionTypes';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {};

const handlers = {
  [EXPERIMENT.ADD_EXPERIMENT]: (state, action) => ({ ...state, ...action.payload }),
  [EXPERIMENT.REMOVE_EXPERIMENT]: (state, action) => {
    const cloneState = cloneDeep(state);
    delete state[action.payload];
    return cloneState;
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
