/* eslint-disable spellcheck/spell-checker, import/no-named-as-default, import/no-unresolved */
import React, { useState, createContext, useContext, useRef, useCallback } from 'react';
import io from 'socket.io-client';
import { HOST } from 'configs';
import { JSONParse } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';

const socketUrl = HOST.SOCKET.URI;
const authPrefix = HOST.API.AUTH_PREFIX;

const SocketContext = createContext(null);

export const SocketEvents = {
  SUBSCRIBE: 'subscribe',
  SYNC_PROFILE: 'sync_profile',
  SYNC_CUSTOM_VOCABULARY: 'sync_custom_vocabulary',
  SYNC_ACTIVITY_EVENT: 'sync_activity_event',
  SYNC_ACTIVITY_COUNTER: 'sync_activity_counter',
  SYNC_CALENDAR: 'sync_calendar',
  SUBSCRIPTION_MESSAGE: 'subscription_message',
};

const SocketProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const socketRef = useRef(null);
  const [connected, setConnected] = useState(false);
  const emitters = useRef({});
  const listenersMap = useRef(new Map());

  const connect = useCallback((token) => {
    socketRef.current = io(socketUrl, {
      path: '/websockets',
      auth: { token: `${authPrefix} ${token}` },
      autoConnect: true,
      transports: ['websocket'],
      upgrade: true,
      reconnectionDelayMax: 60 * 60 * 1000,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1 * 1000,
      randomizationFactor: 1.5,
    });

    socketRef.current.on('disconnect', () => {
      setConnected(false);
    });

    socketRef.current.on('connect', () => {
      setConnected(true);
    });

    socketRef.current.on('reconnect', () => {
      setConnected(true);

      Object.keys(emitters.current).forEach((event) => {
        socketRef.current.emitWithAck(event, ...emitters.current[event]);
      });
    });

    socketRef.current.on('connect_error', (error) => {
      enqueueSnackbar('Socket connection error!', { variant: 'error' });
    });

    return socketRef.current;
  }, []);

  const disconnect = useCallback(() => {
    setConnected(false);
    if (!socketRef.current) return;
    socketRef.current.off('disconnect');
    socketRef.current.off('connect');
    socketRef.current.off('reconnect');
    socketRef.current.disconnect();
  }, []);

  const subscribe = useCallback((event, callback) => {
    const listeners = listenersMap.current;

    if (!listeners.has(event)) {
      listeners.set(event, new Set());
      socketRef.current.on(event, (data) => {
        listeners.get(event).forEach((cb) => cb(data));
      });
    }

    const callbacks = listeners.get(event);

    const isCallbackExist = [...callbacks].some((cb) => cb.toString() === callback.toString());

    if (!isCallbackExist) {
      listeners.get(event).add(callback);
    }

    return () => {
      const eventListeners = listeners.get(event);

      if (eventListeners) {
        eventListeners.delete(callback);
      }

      if (eventListeners?.size === 0) {
        listeners.delete(event);
        socketRef.current.off(event);
      }
    };
  }, []);

  const unsubscribe = useCallback((event, cb) => {
    socketRef.current?.off(event, cb);
  }, []);

  const emitWithAckEvent = useCallback((event, ...args) => {
    emitters.current[event] = { ...args };
    socketRef.current?.emitWithAck(event, ...args);
  }, []);

  const onSubscriptionMessage = useCallback(
    (event, callback) => {
      if (!socketRef.current) {
        return () => {};
      }
      const unSubscriptionMessage = subscribe(SocketEvents.SUBSCRIPTION_MESSAGE, (data) => {
        if (data.event === event) {
          if (data.data) data.data = JSONParse(data.data) || data.data;
          callback(data);
        }
      });

      return unSubscriptionMessage;
    },
    [subscribe],
  );

  return (
    <SocketContext.Provider
      value={{
        connected,
        connect,
        subscribe,
        emitWithAckEvent,
        disconnect,
        unsubscribe,
        onSubscriptionMessage,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === null) {
    throw new Error('useSocket must be used within SocketProvider');
  }

  return context;
};

export { useSocket, SocketProvider };
