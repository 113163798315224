/* eslint-disable spellcheck/spell-checker */
const generic = {
  prod: {
    url_protocol: 'https',
    api_domain: 'api.krisp.ai',
    socket_url: 'websocket.krisp.ai',
    prefix: 'v2',
    base_domain: 'https://app.krisp.ai/',
    google_client: '161125413480-58qgupfi1rpti265uqe042as8rc3d8aq.apps.googleusercontent.com',
    stripe: 'pk_live_8a0CoK0nJGWSjeqz3zjQ5im8',
    download_domain: 'https://download.krisp.ai',
    hotjar: true,
    ga_event_name: 'krisp-event',
    amplitude_deployment_key: 'client-FlfhM0TtHBwfCbUq42ko070nvnMs9gTi',
    typeform_form_id: 'cu5oITRB',
    amplitude_api_key: '01b641bf88b394e1f502ca56d9f6d0a1',
    cc_url: 'https://account.krisp.ai',
    gtag_id: 'G-1KQ247DGBJ',
    tag_manager_id: 'GTM-K8NQ2MS',
  },
  dev: {
    url_protocol: 'https',
    api_domain: 'dev.api.krisp.ai',
    socket_url: 'stage.websocket.krisp.ai',
    prefix: 'v2',
    base_domain: 'https://dev.app.krisp.ai/',
    google_client: '959351245623-t1q8jstjm1q3667ms4ps5tn751akamec.apps.googleusercontent.com',
    stripe: 'pk_test_GVV7Z3THlSR3IbwQCZfTHNhu',
    download_domain: 'https://dev.download.krisp.ai',
    ga_event_name: 'krisp-event-stage',
    typeform_form_id: 'cu5oITRB',
    amplitude_deployment_key: 'client-pPrDFZdFfvOliRgJ3GmIc5tC5msIDF2u',
    amplitude_api_key: 'e4481540598f67b97ef981a90bb4e490',
    cc_url: 'https://dev.account.krisp.ai',
    gtag_id: 'G-ZCP24MJ0RW',
    tag_manager_id: 'GTM-N9JMGC4S',
  },
  stage: {
    url_protocol: 'https',
    api_domain: 'stage.api.krisp.ai',
    socket_url: 'stage.websocket.krisp.ai',
    prefix: 'v2',
    base_domain: 'https://stage.app.krisp.ai/',
    google_client: '959351245623-i3djdvia7n2o54ga780halm96h6caprk.apps.googleusercontent.com',
    stripe: 'pk_test_GVV7Z3THlSR3IbwQCZfTHNhu',
    download_domain: 'https://stage.download.krisp.ai',
    ga_event_name: 'krisp-event-stage',
    typeform_form_id: 'cu5oITRB',
    amplitude_deployment_key: 'client-pPrDFZdFfvOliRgJ3GmIc5tC5msIDF2u',
    amplitude_api_key: '6d5fd31691509c665fc2c20a8b8c8612',
    cc_url: 'https://stage.account.krisp.ai',
    gtag_id: 'G-ZCP24MJ0RW',
    tag_manager_id: 'GTM-N9JMGC4S',
  },
  local: {
    url_protocol: 'http',
    api_domain: 'localhost:4567',
    socket_url: 'localhost:4569',
    prefix: 'v2',
    base_domain: 'http://localhost:3000/',
    google_client: '959351245623-h63d0kgpjb31jli01vcu78jsddljm582.apps.googleusercontent.com',
    stripe: 'pk_test_GVV7Z3THlSR3IbwQCZfTHNhu',
    download_domain: 'http://localhost:4567/v2/download',
    ga_event_name: 'krisp-event-stage',
    typeform_form_id: 'cu5oITRB',
    amplitude_deployment_key: 'client-pPrDFZdFfvOliRgJ3GmIc5tC5msIDF2u',
    amplitude_api_key: '6d5fd31691509c665fc2c20a8b8c8612',
    cc_url: 'http://localhost:3000',
    gtag_id: 'G-ZCP24MJ0RW',
    tag_manager_id: 'GTM-N9JMGC4S',
  },
  ngrok: {
    url_protocol: 'https',
    api_domain: 'krisp.ngrok.io',
    socket_url: 'stage.websocket.krisp.ai',
    prefix: 'v2',
    base_domain: 'http://localhost:3000/',
    google_client: '161125413480-ei9hnplb6ln6891airdduc961i8nu9r4.apps.googleusercontent.com',
    stripe: 'pk_test_GVV7Z3THlSR3IbwQCZfTHNhu',
    download_domain: 'krisp.ngrok.io/v2/download',
    ga_event_name: 'krisp-event-stage',
    amplitude_deployment_key: 'client-pPrDFZdFfvOliRgJ3GmIc5tC5msIDF2u',
    amplitude_api_key: 'e4481540598f67b97ef981a90bb4e490',
    cc_url: 'http://localhost:3000',
    gtag_id: 'G-ZCP24MJ0RW',
    tag_manager_id: 'GTM-N9JMGC4S',
  },
  plive: {
    url_protocol: 'https',
    api_domain: 'plive.api.krisp.ai',
    socket_url: 'plive.websocket.krisp.ai',
    prefix: 'v2',
    base_domain: 'https://plive.app.krisp.ai/',
    google_client: '959351245623-ao73nuofhorjqg4kvuiqjbjcp00qeegi.apps.googleusercontent.com',
    stripe: 'pk_test_GVV7Z3THlSR3IbwQCZfTHNhu',
    download_domain: 'https://plive.download.krisp.ai',
    hotjar: false,
    typeform_form_id: 'cu5oITRB',
    amplitude_deployment_key: 'client-D6LzVOXSANd5jtuVTfaNMXkBZB7TjmhE',
    amplitude_api_key: '223c933eef9ef036245788043d495845',
    cc_url: 'https://plive.account.krisp.ai',
    gtag_id: 'G-ZCP24MJ0RW',
    tag_manager_id: 'GTM-N9JMGC4S',
  },
};

export const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'development' ? 'dev' : env;
  return env;
};

const env = getENV() || 'dev';
const main = generic[env];

export const GENERAL = {
  WEBSITE: 'https://krisp.ai/',
  BASE_DOMAIN: main.base_domain,
  DOWNLOAD_DOMAIN: main.download_domain,
  SUPPORT_WEBSITE: 'https://help.krisp.ai/',
  NEWS_WEBSITE: 'https://whatsnew.krisp.ai/',
  CC_URL: main.cc_url,
  DEMO_RECORDING_URL: 'https://cdn.krisp.ai/demo-meeting/demo_v1.mp3',
};

export const HOST = {
  API: {
    URL: `${main.url_protocol}://${main.api_domain}`,
    API_PREFIX: main.prefix,
    PUBLIC_API_PREFIX: main.public_api_prefix,
    AUTH_HEADER: 'Authorization',
    AUTH_PREFIX: 'Bearer',
  },
  SOCKET: {
    URI: `${main.url_protocol}://${main.socket_url}`,
  },
};

export const IS_LOCAL = env === 'local';

export const ACCESS_TOKENS = {
  GOOGLE_CLIENT: main.google_client,
  STRIPE_KEY: main.stripe,
  GA_EVENT_NAME: main.ga_event_name,
  SENTRY_DSN: 'https://51a9689b61d24bc4ae1299fe1828eb2d@o248321.ingest.sentry.io/6041067',
  TYPEFORM_FORM_ID: main.typeform_form_id,
  GOOGLE_RECAPTCHA_V2_KEY: '6LcHHGsbAAAAALpj_Rd2BxAQ3D33UydnaxcICmnF',
  GDPR_COMPLIANCE_DATA_DOMAIN: 'cd7e9c5d-1e9f-4aa8-8be3-5a31b1494624',
  AMPLITUDE_DEPLOYMENT_KEY: main.amplitude_deployment_key,
  AMPLITUDE_API_KEY: main.amplitude_api_key,
  TYPEFORM_API_ID: 'Ybnw83gH',
  HOTJAR_ID: 1347037,
  HOTJAR_SNIPPET_VERSION: 6,
  TINYMCE_API_KEY: 'msww0jme87imsqpje8mycwxnteyq9pvva86otefpa1q3ej9z',
  TAG_MANAGER_ID: main.tag_manager_id,
  GTAG_ID: main.gtag_id,
  QUORA: 'ca51bad3c5de43a082a8bda23b0dbe84',
};
