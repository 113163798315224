/* eslint-disable no-console */
/* eslint-disable spellcheck/spell-checker */
import { ACCESS_TOKENS } from 'configs';
import { cookiesAreEnabled, cookieTypes } from 'initOneTrust';

export const loadQuoraScript = () => {
  try {
    (function (q, e, v, n, t, s) {
      if (q.qp) return;
      n = q.qp = function () {
        n.qp ? n.qp.apply(n, arguments) : n.queue.push(arguments);
      };
      n.queue = [];
      t = document.createElement(e);
      t.async = !0;
      t.src = v;
      s = document.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, 'script', 'https://a.quora.com/qevents.js');
    window.qp('init', ACCESS_TOKENS.QUORA);
  } catch (err) {
    console.log('loadQuoraScript', err);
  }
};

export const QuoraService = {
  trackEvent: function (eventName) {
    if (!window.qp) return;
    cookiesAreEnabled(cookieTypes.FIRST_PARTY).then((enabled) => {
      if (!enabled) return;
      try {
        window.qp('track', eventName);
      } catch (err) {
        console.log('QuoraService:trackEvent', err);
      }
    });
  },
};
