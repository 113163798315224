import { createReducer } from 'utils/reduxHelpers';
import { BILLING, AUTH } from 'redux/actionTypes';

const initialState = null;

const handlers = {
  [BILLING.SET_INVOICES]: (state, action) => action.payload,
  [BILLING.REMOVE_INVOICES]: () => initialState,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
