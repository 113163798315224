import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { buildURL, buildLocalURL, getQueryParam } from 'utils/appHelpers';

const withQueryParams = (Component) =>
  memo((props) => {
    const history = useHistory();

    const replacePageUrl = (params) => {
      const queryString = buildLocalURL(params);
      history.replace(queryString);
    };

    const redirectPage = (url, params) => {
      const fullUrl = buildURL(url, params);
      window.location.replace(fullUrl);
    };

    const newProps = {
      replacePageUrl,
      redirectPage,
      getQueryParam,
      ...props,
    };

    return <Component {...newProps} />;
  });

export default withQueryParams;
