import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux/actionTypes';
import uniqBy from 'lodash/uniqBy';

const initialState = null;

const handlers = {
  [USER.SET_WORDS]: (state, action) => action.payload,
  [USER.ADD_WORDS]: (state, action) => {
    return { ...state, rows: uniqBy([...state.rows, ...action.payload], 'id') };
  },
  [USER.UPDATE_WORD]: (state, action) => {
    const { id, data } = action.payload;
    const updatedRows = state.rows.map((item) => {
      if (item.id === id) item = { ...item, ...data };
      return item;
    });
    return { ...state, rows: updatedRows };
  },
  [USER.DELETE_WORD]: (state, action) => {
    const { id } = action.payload;
    const updatedRows = state.rows.filter((item) => item.id !== id);
    return { ...state, rows: updatedRows };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
