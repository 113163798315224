import { USER } from '../actionTypes';
const setAccounts = (payload) => ({ type: USER.SET_ACCOUNTS, payload });
const setSeats = (payload) => ({ type: USER.SET_SEATS, payload });
const setWaitlist = (payload) => ({ type: USER.SET_WAITLIST, payload });
const removeSeats = () => ({ type: USER.REMOVE_SEATS });
const setBlacklist = (payload) => ({ type: USER.SET_BLACKLIST, payload });
const removeBlacklist = () => ({ type: USER.REMOVE_BLACKLIST });
const setPreferences = (payload) => ({ type: USER.SET_PREFERENCES, payload });
const updatePreferencesPartially = (payload) => ({
  type: USER.UPDATE_PREFERENCES_PARTIALLY,
  payload,
});
const setRecords = (payload) => ({ type: USER.SET_RECORDS, payload });
const updateRecord = (payload) => ({ type: USER.UPDATE_RECORD, payload });
const setPermissions = (payload) => ({ type: USER.SET_PERMISSIONS, payload });
const addPermission = (payload) => ({ type: USER.ADD_PERMISSION, payload });
const addPermissions = (payload) => ({ type: USER.ADD_PERMISSIONS, payload });
const removePermission = (payload) => ({ type: USER.REMOVE_PERMISSION, payload });
const removePermissions = (payload) => ({ type: USER.REMOVE_PERMISSIONS, payload });
const updateAccountTeamName = (payload) => ({ type: USER.UPDATE_ACCOUNT_TEAM_NAME, payload });
const updateAccountDataPartially = (payload) => ({
  type: USER.UPDATE_ACCOUNT_DATA_PARTIALLY,
  payload,
});
const updateTeam = (payload) => ({ type: USER.UPDATE_TEAM, payload });
const partiallyUpdateAccountData = (payload) => ({ type: USER.UPDATE_ACCOUNT_DATA, payload });
const setDomains = (payload) => ({ type: USER.SET_DOMAINS, payload });
const updateDomain = (payload) => ({ type: USER.UPDATE_DOMAIN, payload });
const removeDomain = (payload) => ({ type: USER.REMOVE_DOMAIN, payload });
const removeDomains = (payload) => ({ type: USER.REMOVE_DOMAINS, payload });
const setCoWorkers = (payload) => ({ type: USER.SET_CO_WORKERS, payload });
const setAudioDevices = (payload) => ({ type: USER.SET_AUDIO_DEVICES, payload });
const setMeeting = (payload) => ({ type: USER.SET_MEETING, payload });
const setMeetingRecurringMeetings = (payload) => ({
  type: USER.SET_RECURRING_MEETINGS,
  payload,
});
const setMeetingAccesses = (payload) => ({
  type: USER.SET_MEETING_ACCESSES,
  payload,
});
const deleteMeeting = (payload) => ({
  type: USER.DELETE_MEETING,
  payload,
});
const updateMeeting = (payload) => ({ type: USER.UPDATE_MEETING, payload });
const updateMeetingTranscript = (payload) => ({ type: USER.UPDATE_MEETING_TRANSCRIPT, payload });
const updateMeetingTranscriptRow = (payload) => ({ type: USER.UPDATE_MEETING_ROW, payload });
const updateMeetingTranscriptPartial = (payload) => ({
  type: USER.UPDATE_MEETING_TRANSCRIPT_PARTIAL,
  payload,
});
const updateMeetingNoteInList = (payload) => ({ type: USER.UPDATE_MEETING_NOTE_IN_LIST, payload });
const setInstallationMeta = (payload) => ({ type: USER.SET_INSTALLATION_META, payload });
const collectUserContacts = (payload) => ({ type: USER.COLLECT_USER_CONTACTS, payload });
const setWords = (payload) => ({ type: USER.SET_WORDS, payload });
const addWords = (payload) => ({ type: USER.ADD_WORDS, payload });
const updateWord = (payload) => ({ type: USER.UPDATE_WORD, payload });
const deleteWord = (payload) => ({ type: USER.DELETE_WORD, payload });
const setOnboardingBannerOpen = (payload) => ({ type: USER.SET_ONBOARDING_BANNER_OPEN, payload });
const updateMeetingSpeaker = (payload) => ({ type: USER.UPDATE_MEETING_SPEAKER, payload });
const setNewMeetingSpeakers = (payload) => ({ type: USER.SET_NEW_SPEAKERS, payload });
const setAvailableTeams = (payload) => ({ type: USER.SET_AVAILABLE_TEAMS, payload });
const checkTranscriptVisibilityAfterSpeechEdit = (payload) => ({
  type: USER.CHECK_TRANSCRIPT_VISIBILITY_AFTER_SPEECH_EDIT,
  payload,
});
const setSelectedAttendeesForAccess = (payload) => ({
  type: USER.SET_SELECTED_ATTENDEES_FOR_ACCESS,
  payload,
});

const actions = {
  setAccounts,
  setSeats,
  setWaitlist,
  removeSeats,
  setBlacklist,
  removeBlacklist,
  setPreferences,
  updatePreferencesPartially,
  setRecords,
  updateRecord,
  setPermissions,
  addPermission,
  addPermissions,
  removePermission,
  removePermissions,
  updateAccountTeamName,
  updateAccountDataPartially,
  updateTeam,
  partiallyUpdateAccountData,
  setDomains,
  updateDomain,
  removeDomain,
  removeDomains,
  setCoWorkers,
  setAudioDevices,
  setMeeting,
  setMeetingRecurringMeetings,
  setMeetingAccesses,
  deleteMeeting,
  updateMeeting,
  updateMeetingTranscript,
  updateMeetingTranscriptRow,
  updateMeetingTranscriptPartial,
  updateMeetingNoteInList,
  setInstallationMeta,
  collectUserContacts,
  setWords,
  addWords,
  updateWord,
  deleteWord,
  setOnboardingBannerOpen,
  updateMeetingSpeaker,
  setNewMeetingSpeakers,
  setAvailableTeams,
  checkTranscriptVisibilityAfterSpeechEdit,
  setSelectedAttendeesForAccess,
};

export default actions;
